import React, {createContext, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {useTheme} from "@mui/material";
import AlertDialog from "../AlertDialog";
import {strings} from "../../values/strings";

export const AlertContext = createContext({
    alert: ({title, content, type, confirmText, confirmColor, action}) => {},
    confirm: () => {},
    cancel: () => {},
})

function AlertDialogProvider(props) {
    const theme = useTheme()

    const {
        children,
    } = props;


    const [open, setOpen] = useState(false)
    const [confirmation, setConfirmation] = useState({})

    const alert = ({title, content, contentProps, type, confirmText, confirmColor, action}) => {
        setConfirmation({
            title,
            content,
            type,
            confirmText,
            color: confirmColor,
            action,
            contentProps,
        })
        setOpen(true)
    }

    const confirm = (event, newValue) => {
        setOpen(false)
        if (confirmation?.action) confirmation.action(event, newValue)
    }

    const cancel = () => {
        setOpen(false)
    }

    const context = useMemo(() => ({
        alert,
        confirm,
        cancel,
    }), [
        open,
        confirmation,
    ])

    return (
        <AlertContext.Provider value={context}>
            <AlertDialog
                open={open}
                title={confirmation.title}
                content={confirmation.content}
                type={confirmation.type}
                cancelText={strings.cancel}
                confirmText={confirmation.confirmText}
                contentProps={confirmation.contentProps}
                cancelProps={{
                    sx: {
                        color: `${theme.palette.primary.contrastText}80`
                    }
                }}
                confirmProps={{
                    color: confirmation.color
                }}
                onClose={cancel}
                confirmClick={confirm}
            />
            {children}
        </AlertContext.Provider>
    )
}

AlertDialogProvider.propTypes = {
    children: PropTypes.any,
}

export default AlertDialogProvider
