import React from "react";
import PropTypes from "prop-types";
import {Stack} from "@mui/material";

function MiniToolbar(
    {
        children,
        sx,
        ...rest
    }
) {
    return (
        <Stack
            direction={'row'}
            spacing={1}
            m={1}
            p={.5}
            px={1.5}
            alignItems={'center'}
            justifyContent={'end'}
            // alignSelf={'end'}
            {...rest}
            sx={{
                minWidth: 0,
                borderRadius: 8,
                backdropFilter: "blur(0.625rem) brightness(0.9)",
                WebkitBackdropFilter: "blur(0.625rem) brightness(0.9)",
                ...sx,
            }}
        >
            {Array.isArray(children) ?
                children.map((_child, _index) => React.cloneElement(_child, {key: _index}))
                : children
            }
        </Stack>
    )
}

MiniToolbar.propTypes = {
    children: PropTypes.any,
}

MiniToolbar.defaultProps = {
}

export default MiniToolbar