import PropTypes from "prop-types";
import {Box, Stack, TextField, useTheme} from "@mui/material";
import styled from "@emotion/styled";
import SquareIconButton from "../../components/SquareIconButton";
import {strings} from "../../values/strings";
import {DatePicker, DateTimePicker, TimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";

function CustomSwitch({value = false, changeValue, expanded, }) {

    const expandedProps = expanded ? {
        sx: {
            flexGrow: 1,
            width: 0,
        },
        buttonSize: 42,
        label: ' '
    } : {}

    const buttonProps = (_value, _focused) => ({
        color: 'secondary',
        buttonSize: 28,
        // disableRipple: true,
        onClick: (e) => {
            e.stopPropagation()
            changeValue(_value)
        },
        ...expandedProps,
        ..._focused ? {
            variant: 'contained',
            sx: expandedProps.sx,
        } : {
            sx: {
                ...expandedProps.sx,
                background: (theme) => `${theme.palette.secondary.main}10`,
            },
        },
    })

    return (
        <Stack
            direction={'row'}
            spacing={1}
            width={'100%'}
        >
            <SquareIconButton size={20} {...buttonProps(true, value)}>circle</SquareIconButton>
            <SquareIconButton size={22} {...buttonProps(false, !value)}>close</SquareIconButton>
        </Stack>
    )
}

function CustomTextField({value = '', changeValue, expanded}) {
    const theme = useTheme()
    const expandProps = expanded ? {
        multiline: true,
        sx: {
            width: '100%',
        }
    } : {}
    return (
        <TextField
            value={value}
            onChange={e => changeValue(e.target.value)}
            size={'small'}
            {...expandProps}
            inputProps={{
                style: {
                    ...theme.typography.subtitle2,
                    paddingLeft: 8,
                }
            }}
            InputProps={{
                endAdornment: (
                    <SquareIconButton size={18} onClick={() => changeValue('')}>
                        close
                    </SquareIconButton>
                ),
                sx: {
                    pr: '2px',
                },
            }}
            sx={expanded ? expandProps.sx : {maxWidth: 180}}
        />
    )
}

function CustomDate({value: inputValue = null, changeValue, expanded, type}) { // type = date, datetime (default), time, null
    const theme = useTheme()
    const handleAccept = (_data) => changeValue(_data?.toString())
    const clear = () => changeValue('')

    const value = inputValue ? dayjs(inputValue) : null

    const autoType = () => {
        switch (type) {
            case 'time':
                return (
                    <TimePicker
                        formatDensity={'spacious'}
                        value={value}
                        onChange={handleAccept}
                        slotProps={{
                            textField: {
                                size: 'small',
                                inputProps: {
                                    style: theme.typography.subtitle2
                                },
                                sx: {
                                    flexGrow: 1,
                                },
                            },
                            openPickerIcon: {
                                sx: {
                                    fontSize: 20,
                                }
                            },
                            clearButton: {
                                color: 'error',
                            },
                            actionBar: {
                                actions: ['clear'],
                            }
                        }}
                    />
                )

            case 'date':
                return (
                    <DatePicker
                        formatDensity={'spacious'}
                        value={value}
                        onChange={handleAccept}
                        slotProps={{
                            textField: {
                                size: 'small',
                                inputProps: {
                                    style: theme.typography.subtitle2
                                },
                                sx: {
                                    flexGrow: 1,
                                },
                            },
                            openPickerIcon: {
                                sx: {
                                    fontSize: 20,
                                }
                            },
                            clearButton: {
                                color: 'error',
                            },
                            actionBar: {
                                actions: ['clear'],
                            }
                        }}
                    />
                )

            default:
                return (
                    <DateTimePicker
                        formatDensity={'spacious'}
                        value={value}
                        onChange={handleAccept}
                        slotProps={{
                            textField: {
                                size: 'small',
                                inputProps: {
                                    style: theme.typography.subtitle2
                                },
                                sx: {
                                    flexGrow: 1,
                                },
                            },
                            openPickerIcon: {
                                sx: {
                                    fontSize: 20,
                                }
                            },
                            clearButton: {
                                color: 'error',
                            },
                            actionBar: {
                                actions: ['clear'],
                            }
                        }}
                    />
                )
        }
    }

    return (
        <Stack direction={'row'} spacing={.5} alignItems={'center'}>
            {autoType()}
            <SquareIconButton size={18} onClick={clear}>
                close
            </SquareIconButton>
        </Stack>
    )
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
})

function CustomFile({value, changeValue, expanded}) {
    const theme = useTheme()

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0]
        if (selectedFile) {
            changeValue(selectedFile)
        }
    }

    return (
        <SquareIconButton
            component="label"
            buttonSize={30}
            size={20}
            variant={'outlined'}
            label={strings.select_file}
            other={<VisuallyHiddenInput type="file" onChange={handleFileChange} />}
            sx={{...theme.typography.caption, lineHeight: 1, width: expanded && '100%'}}
        >
            attach_file
        </SquareIconButton>
    )
}

function CustomList({value = [], changeValue, expanded, type = 'text'}) {
    const handleChange = (_index = -1, action) => (_value) => {
        changeValue(_prev => {
            let _updated = [..._prev]
            const _finalIndex = _index < 0 ? _prev.length - 1 : _index

            if (action === 'delete') {
                _updated.splice(_finalIndex, 1)
            } else if (action === 'add') {
                _updated.splice(_finalIndex + 1, 0, '')
            } else {
                _updated[_finalIndex] = _value
            }

            return _updated
        })
    }

    const listItem = (_item, _index, _disable) => {
        return (
            <Stack
                key={_index}
                direction={'row'}
                spacing={1}
                alignItems={'center'}
            >
                <AutoType
                    value={_item || ''}
                    type={type}
                    onChange={handleChange(_index)}
                    expanded
                />

                <SquareIconButton hint={strings.add} onClick={handleChange(_index, 'add')}>
                    add_circle
                </SquareIconButton>

                <SquareIconButton hint={strings.add} color={'error'} onClick={handleChange(_index, 'delete')}>
                    do_not_disturb_on
                </SquareIconButton>
            </Stack>
        )
    }

    return (
        <Stack
            direction={'column'}
            spacing={1}
        >
            {Array.isArray(value) && value?.map(listItem)}

            {(!value || value.length === 0) &&
                <div
                    onClick={handleChange(null, 'add')}
                    style={{cursor: 'pointer'}}
                >
                    <Box sx={{pointerEvents: 'none', opacity: .2,}}>
                        {listItem()}
                    </Box>
                </div>
            }
        </Stack>
    )
}

function AutoType(
    {
        value,
        type,
        viewType,
        onChange = (value) => {},
        expanded,
    }
) {
    const inputProps = {
        value,
        changeValue: onChange,
        expanded,
    }

    switch (type) {
        case 'bool': return <CustomSwitch {...inputProps} />
        case 'text': return <CustomTextField {...inputProps} />
        case 'date': return <CustomDate type={viewType} {...inputProps} />
        case 'array': return <CustomList type={viewType} {...inputProps} />
        case 'file': return <CustomFile {...inputProps} />
        default: return <></>
    }
}

AutoType.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    type: PropTypes.string,
    viewType: PropTypes.string,
    expanded: PropTypes.bool,
}

AutoType.defaultProps = {
}

export default AutoType