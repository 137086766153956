import {createTheme} from "@mui/material/styles";
import {darkTheme} from "./index";

const logoTheme = createTheme({
    ...darkTheme,
    typography: {
        ...darkTheme?.typography,
        fontFamily: 'Dancing Script, cursive',
    },
});

export default logoTheme;