import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
    Checkbox,
    FormControlLabel,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import {strings} from "../../values/strings";
import {SwitchContainer} from "@wizlit/react-component";
import CloseFloatingButton from "../../components/CloseFloatingButton";
import UnderbarTabs from "../../components/UnderbarTabs";
import {AlertContext} from "../../components/AlertDialogProvider";
import ScrollableContainer from "../../components/ScrollableContainer";
import Peek from "./Peek";
import ScenarioSelect from "./ScenarioSelect";
import Toolbar from "./Toolbar";
import Summary from "./Summary";
import VariableBox from "./VariableBox";
import {EditPageContext} from "../../pages/EditPage";
import {MaterialIcon} from "../../components/MaterialIcon";
import {variableDataTypes} from "@wizlit/react-widget-board";

const drawerBleeding = 50

function VariableManager(
    {
        sx,
        ...rest
    }
) {
    const theme = useTheme()
    const alertContext = useContext(AlertContext)
    const editPageContext = useContext(EditPageContext)

    const defaultScenario = editPageContext.defaultScenario
    const scenario = editPageContext.selectedScenario
    const scenarios = editPageContext.scenarios
    const countVariables = editPageContext.countVariables
    const countUnusedVariables = editPageContext.countUnusedVariables

    const changeScenario = editPageContext.changeSelectedScenario
    const renameScenario = editPageContext.renameScenario
    const onDefaultScenarioChange = editPageContext.changeDefaultScenario
    const onScenarioCreate = editPageContext.createScenario
    const onScenarioDelete = editPageContext.deleteScenario
    const filterVariable = editPageContext.filterVariable
    const onDeleteAllUnusedVariables = editPageContext.deleteAllUnusedVariables
    const editScenario = editPageContext.editScenario
    const editVariable = editPageContext.editVariable

    const [expanded, setExpanded] = useState(false)
    const [focusedId, setFocusedId] = useState(null)

    const dataTypeKeys = Object.keys(variableDataTypes)

    const dataTypeLabels = {
        'text': strings.text,
        'bool': strings.bool,
        'date': strings.date,
        'array': strings.list,
        'file': strings.file,
        'any': strings.any,
    }

    const [tabIndex, setTabIndex] = useState(0)
    const [variables, setVariables] = useState({})

    const dataType = dataTypeKeys[tabIndex]

    /**
     * SCENARIO
     */

    const defaultName = scenarios[defaultScenario]?.name
    const isDefault = scenario === 'default'

    const selectProps = {
        options: Object.keys(scenarios).filter(key => key !== 'default')?.map(key => ({
            id: key,
            ...scenarios[key],
        })),
        value: scenario,
        onChange: (event) => changeScenario(event.target.value),
        onAdd: async (_event, _newValue) => {
            try {
                const _newScenario = await onScenarioCreate(_newValue)
                if (_newScenario?.id) {
                    setExpanded(true)
                    changeScenario(_newScenario?.id)
                }
            } catch (e) {}
        }
    }

    /**
     * DATA TYPE
     */

    const handleTab = (event, newValue) => {
        setTabIndex(newValue)
    }

    useEffect(() => {
        if (expanded && filterVariable) {
            setVariables(_prev => ({..._prev, [dataType]: filterVariable(scenario, dataType)}))
        }
    }, [scenario, tabIndex, expanded])

    /**
     *
     */

    return (
        <Stack
            direction={'column'}
            sx={{
                width: 'calc(100% - 24px)',
                m: '12px',
                height: drawerBleeding,
                backdropFilter: "blur(0.625rem) brightness(1.8)",
                WebkitBackdropFilter: "blur(0.625rem) brightness(1.8)",
                borderRadius: '8px',
                transition: '.3s',
                boxSizing: 'border-box',
                overflow: 'hidden',
                pointerEvents: 'auto',
                ...expanded ? {
                    width: '100%',
                    m: 0,
                    height: '100%',
                    backdropFilter: "blur(1rem) brightness(1.1)",
                    WebkitBackdropFilter: "blur(1rem) brightness(1.1)",
                    p: '10px',
                } : {},
                ...sx,
            }}
            {...rest}
        >
            <SwitchContainer
                visibleIndex={expanded ? 1 : 0}
                contents={[

                    <Peek
                        height={drawerBleeding}
                        countVariables={countVariables}
                        onClick={() => setExpanded(_prev => !_prev)}
                        selectProps={selectProps}
                    />,

                    <div
                        style={{
                            position: 'relative',
                            flexGrow: 1,
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <ScrollableContainer
                            hideScrollbar
                            sx={{
                                height: '100%',
                            }}
                            scrollProps={{
                                pb: 10,
                            }}
                            topContent={
                                <Summary
                                    countVariables={countVariables}
                                    countUnusedVariables={countUnusedVariables}
                                    onDeleteAllUnusedVariables={onDeleteAllUnusedVariables}
                                />
                            }
                            stickyContent={
                                <Stack
                                    direction={'column'}
                                    spacing={2}
                                    pb={2}
                                >
                                    <Stack
                                        direction={'row'}
                                        spacing={1}
                                        alignItems={'center'}
                                    >
                                        <ScenarioSelect {...selectProps} m={'0px'} />

                                        <Typography
                                            variant={'caption'}
                                            color={'primary.contrastText'}
                                            flexGrow={1}
                                            noWrap
                                            sx={{opacity: .5}}
                                        >
                                            {isDefault ? `${defaultName}` : ''}
                                        </Typography>

                                        <FormControlLabel
                                            control={<Checkbox size={'small'} color={'secondary'} />}
                                            label={strings.set_as_default}
                                            slotProps={{
                                                typography: {
                                                    variant: 'caption',
                                                    color: 'secondary',
                                                    lineHeight: 1,
                                                    noWrap: true,
                                                }
                                            }}
                                            checked={defaultScenario === scenario}
                                            labelPlacement={'start'}
                                            disabled={!scenario || scenario === 'default'}
                                            onChange={(e) => e.target.checked && onDefaultScenarioChange(scenario)}
                                            sx={{
                                                mr: '-5px'
                                            }}
                                        />
                                    </Stack>

                                    <UnderbarTabs
                                        value={tabIndex}
                                        onChange={handleTab}
                                        tabs={dataTypeKeys.map(_key => dataTypeLabels[_key])}
                                        fontSize={14}
                                        tabHeight={32}
                                    />

                                </Stack>
                            }
                        >
                            <SwitchContainer
                                visibleIndex={tabIndex}
                                allGone
                                contents={
                                    dataTypeKeys.map(_dataType =>
                                        variables[_dataType]?.map((_variable, _index) =>
                                            <VariableBox
                                                key={_index}
                                                id={_variable.id}
                                                name={_variable.name}
                                                description={_variable.description}
                                                type={_variable.type}
                                                viewType={_variable.detailedType}
                                                value={_variable.value}
                                                deps={[scenario]}
                                                onClick={(_id) => setFocusedId(_id)}
                                                expanded={(_id) => _id === focusedId}
                                                cancelExpand={() => setFocusedId(null)}
                                                onNameChange={(_id, _value) => editVariable(_id, 'name', _value)}
                                                onDescriptionChange={(_id, _value) => editVariable(_id, 'description', _value)}
                                                onValueChange={(_var, _val) => editScenario(scenario, _var, _val)}
                                                changeViewType={(_id, _value) => editVariable(_id, 'detailedType', _value)}
                                            />
                                        )
                                    )
                                }
                                style={{
                                    height: 'auto'
                                }}
                            />

                            <Typography
                                component={Stack}
                                variant={'subtitle2'}
                                color={'error'}
                                // direction={'row'}
                                spacing={1}
                                justifyContent={'center'}
                                alignItems={'center'}
                                sx={{
                                    background: `${theme.palette.error.main}30`,
                                    borderRadius: 2,
                                    py: 2,
                                    my: 2,
                                }}
                            >
                                <MaterialIcon size={30} weight={700}>warning</MaterialIcon>
                                <div>{strings.do_not_put_sensitive_information}</div>
                            </Typography>

                            <Typography
                                variant={'subtitle2'}
                                color={'primary.contrastText'}
                                align={'center'}
                                mt={3}
                                sx={{opacity: .3}}
                            >
                                {strings.n_variables.format(variables[dataType]?.length)}
                            </Typography>

                        </ScrollableContainer>

                        <Toolbar
                            name={scenarios[scenario]?.name}
                            renameDisabled={isDefault}
                            deleteDisabled={isDefault || scenario === defaultScenario}
                            onRename={async (_event, _newValue) => {
                                try {
                                    await renameScenario(scenario, _newValue)
                                } catch (e) {}
                            }}
                            onDelete={() => {
                                onScenarioDelete(scenario)
                                changeScenario('default')
                            }}
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                left: 0,
                            }}
                        />

                        <CloseFloatingButton
                            fixed={false}
                            onClick={() => setExpanded(false)}
                            style={{
                                position: 'absolute',
                                color: theme.palette.primary.contrastText,
                                bottom: 24,
                                left: '50%',
                                marginRight: '-50%',
                                transform: 'translateX(-50%)',
                            }}
                        />

                    </div>

                ]}
            />
        </Stack>
    )
}

VariableManager.propTypes = {
}

VariableManager.defaultProps = {
}

export default VariableManager