import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Box, Button, Divider, Stack, TextField, Typography, useTheme} from "@mui/material";
import stc from 'string-to-color'
import AutoType from "./AutoType";
import {strings} from "../../values/strings";
import fontColorContrast from "font-color-contrast";
import SquareIconButton from "../../components/SquareIconButton";
import {variableDataTypes} from "@wizlit/react-widget-board";
import {SwitchContainer} from "@wizlit/react-component";

const Indicator = ({id, sx, children, noBackground, ...rest}) => {
    const theme = useTheme()
    const color = noBackground ? theme.palette.primary.contrastText : stc(id)
    const commonSx = {
        background: color,
        mr: '4px',
        ...sx,
    }
    return (
        <Box
            {...rest}
            sx={children ? {
                ...commonSx,
                color: fontColorContrast(color),
                fontSize: 12,
                lineHeight: 1.5,
                height: '20px',
                borderRadius: '4px',
                px: 1,
            } : {
                ...commonSx,
                width: '10px',
                height: '10px',
                borderRadius: '2px',
            }}
        >
            {children}
        </Box>
    )
}

const Content = ({label, children}) => {
    return (
        <Stack
            spacing={1}
        >
            {typeof label === 'string' ?
                <Typography
                    variant={'caption'}
                    color={'primary.contrastText'}
                    noWrap
                    lineHeight={1}
                    px={.5}
                    sx={{
                        opacity: .5
                    }}
                >
                    {label}
                </Typography>
                :
                label
            }

            {children}
        </Stack>
    )
}

function VariableBox(
    {
        id,
        name: defaultName,
        description: defaultDescription,
        type,
        viewType: defaultViewType,
        value: defaultValue,
        deps = [],
        onClick = () => {},
        onNameChange = () => {},
        onDescriptionChange = () => {},
        onValueChange = (id, value) => {},
        changeViewType = (viewType) => {},
        expanded: expandedFunc,
        cancelExpand = () => {},
    }
) {
    const theme = useTheme()

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [view, setView] = useState(null)
    const [value, setValue] = useState('')
    const expanded = typeof expandedFunc === 'function' ? expandedFunc(id) : expandedFunc

    const viewTypes = variableDataTypes[type]

    const handleNameChange = (e) => {
        setName(e.target.value)
        onNameChange(id, e.target.value)
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value)
        onDescriptionChange(id, e.target.value)
    }

    const handleValueChange = (_value) => {
        setValue(_prev => {
            const _updated = typeof  _value === 'function' ? _value(_prev) : _value
            onValueChange(id, _updated)
            return _updated
        })
    }

    const handleViewChange = () => {
        const _nextIndex = viewTypes.indexOf(view) + 1
        const _nextViewType = viewTypes[_nextIndex]
        const _view = _nextViewType ? _nextViewType : viewTypes[0]

        setView(_view)
        changeViewType(id, _view)
    }

    useEffect(() => {
        setName(defaultName || '')
        setDescription(defaultDescription || '')
        setView(defaultViewType)
        setValue(defaultValue || '')
    }, [defaultValue, defaultName, defaultDescription, defaultViewType, ...deps])

    const columnTypes = ['date']
    const showOnlyExpand = ['array']

    return (
        <Box
            sx={{
                transition: '.2s',
                ...expanded ? {
                    background: theme.palette.primary.light,
                    borderRadius: 3,
                    p: 2,
                    my: 2,
                } : {
                    minHeight: 50,
                }
            }}
        >
            <SwitchContainer
                visibleIndex={expanded ? 1 : 0}
                allGone
                contents={[

                    <Stack
                        direction={columnTypes.includes(type) ? 'column' : 'row'}
                        spacing={1}
                        height={'100%'}
                        alignItems={'center'}
                        {...columnTypes.includes(type) && {
                            direction: 'column',
                            alignItems: 'left',
                            spacing: 0,
                            py: 1,
                        }}
                    >
                        <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems={'center'}
                            justifyContent={'left'}
                            component={Button}
                            flexGrow={1}
                            height={'100%'}
                            minHeight={35}
                            onClick={() => onClick(id)}
                        >
                            <Indicator id={id} />

                            <Typography
                                variant={'subtitle2'}
                                color={'primary.contrastText'}
                                noWrap
                                lineHeight={1}
                            >
                                {name}
                            </Typography>
                        </Stack>

                        {!showOnlyExpand.includes(type) &&
                            <Stack direction={'row'} alignItems='center' spacing={1}>
                                {!!viewTypes &&
                                    <SquareIconButton
                                        hint={strings.change_view}
                                        onClick={handleViewChange}
                                        color={'info'}
                                    >
                                        swap_horizontal_circle
                                    </SquareIconButton>
                                }

                                <Box flexGrow={1}>
                                    <AutoType
                                        type={type}
                                        viewType={view}
                                        value={value}
                                        onChange={handleValueChange}
                                    />
                                </Box>
                            </Stack>
                        }
                    </Stack>,

                    <Stack
                        direction={'column'}
                        spacing={2.5}
                        pb={2}
                    >
                        <SquareIconButton sx={{alignSelf: 'center'}} onClick={cancelExpand}>
                            expand_less
                        </SquareIconButton>

                        <Content label={strings.variable_name}>
                            <TextField
                                value={name}
                                color={'secondary'}
                                size={'small'}
                                onChange={handleNameChange}
                                inputProps={{
                                    style: {
                                        ...theme.typography.subtitle2,
                                    }
                                }}
                            />

                            <Stack direction={'row'} spacing={1}>
                                <Indicator id={id}>
                                    {id?.length > 14 ? id?.slice(2, 8) + ' ... ' + id?.slice(-6, -2) : id?.slice(2, -2)}
                                </Indicator>

                                {type && <Indicator noBackground>{type}</Indicator>}
                            </Stack>
                        </Content>

                        <Content label={strings.variable_description}>
                            <TextField
                                value={description}
                                color={'secondary'}
                                size={'small'}
                                multiline
                                minRows={4}
                                onChange={handleDescriptionChange}
                                inputProps={{
                                    style: {
                                        ...theme.typography.subtitle2,
                                    }
                                }}
                            />
                        </Content>

                        <Divider />

                        <SquareIconButton
                            label={strings.change_view}
                            onClick={handleViewChange}
                            color={'info'}
                            disabled={!viewTypes}
                            sx={{
                                alignSelf: 'center'
                            }}
                        >
                            swap_horizontal_circle
                        </SquareIconButton>

                        <Content>
                            <AutoType
                                type={type}
                                viewType={view}
                                value={value}
                                onChange={handleValueChange}
                                expanded
                            />
                        </Content>
                    </Stack>

                ]}
            />
        </Box>
    )
}

VariableBox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    viewType: PropTypes.string,
    value: PropTypes.any,
    deps: PropTypes.array,
    expanded: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    onClick: PropTypes.func,
    onNameChange: PropTypes.func,
    onDescriptionChange: PropTypes.func,
    onValueChange: PropTypes.func,
    changeViewType: PropTypes.func,
    cancelExpand: PropTypes.func,
}

VariableBox.defaultProps = {
}

export default VariableBox