const colors = {
    primaryMain: "#1f2428",
    primaryLight: "#282f34",
    // primaryLight: "#34383b",
    primaryDark: "#000000",
    primaryContrastText: "#ffffff",

    // secondaryMain: "#d9a851",
    secondaryMain: "#F9C37A",
    secondaryLight: "#fff6aa",
    secondaryDark: "#c4934c",
    secondaryContrastText: "#1f2428",

    tertiaryMain: "#1f2428", // (3) original
    // tertiaryMain: "#1B2525", // (1) genesis green + original 50%
    // tertiaryMain: "#1A2524", // (2) genesis green + royal blue + original 60%
    tertiaryLight: "#282f34",
    tertiaryDark: "#000000",
    tertiaryContrastText: "#ffffff",

    textPrimary: "#ffffff",
    textSecondary: 'rgba(255,255,255,0.5)',
    textDisabled: 'rgba(255,255,255,0.15)',

    infoMain: "#29b6f6",
    infoLight: "#4fc3f7",
    infoDark: "#0288d1",
    infoContrastText: "#1f2428",

    successMain: "#80ec92",
    successLight: "#b4ffc3",
    successDark: "#4cb963",
    successContrastText: "#1f2428",

    warningMain: "#ffa726",
    warningLight: "#ffb74d",
    warningDark: "#f57c00",
    warningContrastText: "#1f2428",

    errorMain: "#f55365",
    errorLight: "#ff8793",
    errorDark: "#bc143b",
    errorContrastText: "#ffffff",

    ready: "#ffa726",
    onGoing: "#80ec92",
    end: "#f55365",

    notice: "#f55365",
    event: "#80ec92",
    member: "#29b6f6",
    joinRequest: "#ffa726",


    main_blue: '#1BAEF8',
    main_green: '#61C2A2',
    main_red: '#F55165',
    main_yellow: '#F9C37A',
    main_purple: '#b148d2',

    demote: '#b148d2',
    promote: '#61C2A2',
    kick: '#F55165',
    detail: "#34383b",

}

export default colors;