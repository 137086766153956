import React from "react";
import PropTypes from "prop-types";
import {styled, Tab, Tabs} from "@mui/material";

const CustomTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))(({ theme, tabheight }) => ({
    minHeight: `${tabheight}px !important`,
    pointerEvents: 'auto',
    '& .MuiTabs-indicator': {
        minHeight: `4px`,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        transitionDuration: '150ms',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 30,
        width: '100%',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 12,
    },
}));

const defaultFontSize = 18

const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme, fontSize, tabheight }) => ({
        textTransform: 'uppercase',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(fontSize),
        color: `${theme.palette.primary.contrastText}`,
        minWidth: `30px !important`,
        minHeight: `${tabheight}px !important`,
        maxHeight: `${tabheight}px !important`,
        padding: `${4 * (fontSize / defaultFontSize)}px ${8 * (fontSize / defaultFontSize)}px`,
        marginRight: 8,
        // zIndex: 1,
        '&.Mui-selected': {
            color: `${theme.palette.secondary.main}`,
        },
        '&.Mui-focusVisible': {
        },
    }),
);

function UnderbarTabs(props) {
    const {value, onChange, tabs, tabHeight = 42, fontSize = defaultFontSize, ...rest} = props
    return (
        <CustomTabs
            value={value}
            onChange={onChange}
            tabheight={tabHeight}
            {...rest}
        >
            {tabs.map((item, index) =>
                <CustomTab
                    key={index}
                    value={item.id || index}
                    label={item.text || item.id || item}
                    fontSize={fontSize}
                    tabheight={tabHeight}
                />
            )}
        </CustomTabs>
    )
}

UnderbarTabs.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    tabs: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.any),
        PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        )
    ]),
    tabHeight: PropTypes.number,
    fontSize: PropTypes.number,
}

UnderbarTabs.defaultProps = {
    tabHeight: 42,
    fontSize: 18,
}

export default UnderbarTabs