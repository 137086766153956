const strings = {
    logo_name: "위즐릿",
    share: '공유',
    run: '실행',
    design: '디자인',
    function: '기능',
    property: '속성',
    property_short: '속성',
    component: '구성 요소',
    theme: '테마',
    page_size: '페이지 크기',
    download: '다운로드',
    color: '색상',
    input: '입력',
    variable: '변수',
    deploy: '배포',
    delete_size: '크기 삭제',
    delete: '삭제',
    cancel: '취소',
    add_widget: '위젯 추가',
    default: '기본',
    archive: '보관',
    this_project: '현재 작품',
    my_projects: '내 다른 작품',
    show_all_n_variables: '{0} 개 변수 모두 보기',
    n_unused: '{0} 개 미사용',
    n_variables: '{0} 개 변수',
    page_size_describe: '화면 크기에 따라 페이지의 크기가 달라집니다\n' +
        '\n- 데스크탑: 20 ~ 10' +
        '\n- 테블릿: 10 ~ 6' +
        '\n- 모바일: 6 ~ 4',
    delete_widget_title: '위젯을 정말로 삭제하시겠습니까?',
    delete_widget_description: '모든 페이지 크기에서 위젯이 완전히 삭제됩니다',
    archive_widget_title: '위젯을 보관하시겠습니까?',
    archive_widget_description: '- 위젯은 삭제되지 않습니다\n- 현재 페이지 크기에서만 위젯이 사라집니다',
    searched_widget: '찾아본 위젯',
    never_used: '한 번도 사용 안함',
    delete_all: '전체 삭제',
    set_as_default: '기본으로 설정',
    any: '기타',
    bool: '참거짓',
    list: '목록',
    text: '문자',
    date: '날짜',
    file: '파일',
    add: '추가',
    select_file: '파일 선택',
    create_new_scenario: '새 시나리오 만들기',
    create: '생성',
    scenario_name: '시나리오 이름',
    delete_scenario: '시나리오 삭제',
    rename: '이름 변경',
    rename_scenario: '시나리오 이름 변경',
    delete_all_unused_variables: '사용하지 않는 변수 모두 삭제',
    do_not_put_sensitive_information: '중요한 정보는 입력하지 마세요',
    variable_name: '변수명',
    variable_description: '변수 설명',
    variable_value: '변수값',
    close: '닫기',
    change_view: '보기 방식 전환',

}

export const KR = strings;