import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, TextField,
    useTheme
} from "@mui/material";



function AlertDialog(props) {
    const theme = useTheme();

    const {
        title,
        content,
        type,
        cancelText,
        confirmText,

        titleProps,
        contentProps,
        cancelProps,
        confirmProps,

        confirmClick,

        loading,
        ...rootProps
    } = props;

    const {
        open,
        onClose
    } = rootProps;

    const textFieldRef = useRef()

    const [mTitle, setTitle] = useState("");
    const [mContent, setContent] = useState('');
    const [mOriginalContent, setOriginalContent] = useState('');
    const [mCancel, setCancel] = useState('cancel');
    const [mConfirm, setConfirm] = useState('ok');

    const textFieldType = type === 'textField'

    const confirmAction = (e) => {
        if (confirmClick) {
            confirmClick(e, mContent)
        } else {
            onClose()
        }
    }

    const handleKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                confirmAction(event)
                break
        }
    }

    useEffect(() => {
        if (open) {
            setTitle(title);
            setContent(content || '');
            setOriginalContent(content || '');
            setConfirm(confirmText);
            setCancel(cancelText);
            textFieldRef.current?.addEventListener('keydown', handleKeyDown)
        }
        return () => {
            textFieldRef.current?.removeEventListener('keydown', handleKeyDown)
        }
    }, [open, textFieldRef.current])

    return (
        <Dialog
            onDragStart={e => e.preventDefault()}
            {...rootProps}
            sx={{
                userSelect: 'none',
                msUserSelect: 'none',
                MozUserSelect: "none",
                WebkitUserSelect: "none",
                ...rootProps?.sx
            }}
            PaperProps={{
                ...rootProps?.PaperProps,
                sx: {
                    minWidth: 250,
                    maxWidth: 350,
                    borderRadius: 2,
                    background: theme.palette.primary.light,
                    color: theme.palette.primary.contrastText,
                    ...rootProps?.PaperProps?.sx
                }
            }}
        >
            {Boolean(mTitle) &&
                <DialogTitle variant={'subtitle1'} fontWeight={'bold'} {...titleProps}>
                    {mTitle}
                </DialogTitle>
            }

            <DialogContent
                sx={{
                    paddingBottom: '0px',
                }}
            >
                {typeof mContent === 'object' ? mContent :
                    textFieldType ?
                        <TextField
                            ref={textFieldRef}
                            label='Label'
                            variant="outlined"
                            color='secondary'
                            value={mContent}
                            autoFocus
                            {...contentProps}
                            onChange={e => {
                                setContent(e.target.value)
                                if (contentProps?.onChange) contentProps?.onChange(e)
                            }}
                            sx={{
                                my: 1,
                                ...contentProps.sx,
                            }}
                        />
                        :
                        <DialogContentText
                            color={"inherit"}
                            variant={"subtitle2"}
                            sx={{
                                opacity: Boolean(mTitle) ? .5 : 1,
                                wordBreak: "keep-all",
                                whiteSpace: "pre-line",
                            }}
                            {...contentProps}
                        >
                            {mContent}
                        </DialogContentText>
                }
            </DialogContent>

            <DialogActions>
                {Boolean(mCancel) &&
                    <Button
                        color={"inherit"}
                        autoFocus={!textFieldType}
                        disabled={loading}
                        onClick={onClose}
                        {...cancelProps}
                    >
                        {mCancel}
                    </Button>
                }

                {Boolean(mConfirm) &&
                    <Button
                        color={"secondary"}
                        disabled={loading || (textFieldType && (!mContent || mContent.trim().length <= 0 || mOriginalContent.trim() === mContent.trim()))}
                        onClick={confirmAction}
                        {...confirmProps}
                    >
                        {mConfirm}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

AlertDialog.propTypes = {
    maxWidth: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    confirmClick: PropTypes.func,

    title: PropTypes.string,
    content: PropTypes.any,
    type: PropTypes.oneOf(['textField', null]),
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,

    titleProps: PropTypes.shape(DialogTitle.propTypes),
    contentProps: PropTypes.shape(DialogContentText.propTypes),
    cancelProps: PropTypes.shape(Button.propTypes),
    confirmProps: PropTypes.shape(Button.propTypes),

    loading: PropTypes.bool,
}

export default AlertDialog;
