import React, {useContext} from "react";
import PropTypes from "prop-types";
import {
    Box,
    useTheme
} from "@mui/material";
import {strings} from "../../values/strings";
import {AlertContext} from "../../components/AlertDialogProvider";
import SquareIconButton from "../../components/SquareIconButton";
import MiniToolbar from "../../components/MiniToolbar";

function Toolbar(
    {
        name,
        renameDisabled,
        deleteDisabled,
        onRename = () => {},
        onDelete = () => {},
        sx,
        ...rest
    }
) {
    const theme = useTheme()
    const alertContext = useContext(AlertContext)

    return (
        <MiniToolbar
            {...rest}
            sx={{
                background: theme.palette.primary.main,
                ...sx,
            }}
        >
            <SquareIconButton
                hint={strings.rename}
                onClick={() => alertContext.alert({
                    title: strings.rename_scenario,
                    type: 'textField',
                    content: name,
                    contentProps: {
                        label: strings.scenario_name
                    },
                    confirmText: strings.rename,
                    confirmColor: 'secondary',
                    action: onRename,
                })}
                disabled={renameDisabled}
                size={22}
            >
                text_fields
            </SquareIconButton>

            <Box flexGrow={1} />

            <SquareIconButton
                hint={strings.delete}
                color={'error'}
                onClick={() => alertContext.alert({
                    content: strings.delete_scenario,
                    confirmText: strings.delete,
                    confirmColor: 'error',
                    action: onDelete
                })}
                disabled={deleteDisabled}
            >
                delete
            </SquareIconButton>
        </MiniToolbar>
    )
}

Toolbar.propTypes = {
    name: PropTypes.string,
    renameDisabled: PropTypes.bool,
    deleteDisabled: PropTypes.bool,
    onRename: PropTypes.func,
    onDelete: PropTypes.func,
}

Toolbar.defaultProps = {
}

export default Toolbar