import React, {useContext, useRef, useState} from "react";
import PropTypes from "prop-types";
import {SwitchContainer} from "@wizlit/react-component";
import {WidgetArchive, WidgetCategoryGrid} from "@wizlit/react-widget-board";
import { Global } from '@emotion/react';
import {
    Divider,
    Stack,
    SwipeableDrawer, Typography, useTheme
} from "@mui/material";
import dimens from "../../values/dimens";
import SearchBox from "../../components/SearchBox";
import {MaterialIcon} from "../../components/MaterialIcon";
import ListItem from "../../components/ListItem";
import SwipeThumb from "../../components/SwipeThumb";
import {strings} from "../../values/strings";
import ScrollableContainer from "../../components/ScrollableContainer";
import {widgetCategories} from "@wizlit/react-widget";
import TextButton from "../../components/TextButton";
import {AlertContext} from "../../components/AlertDialogProvider";

const drawerBleeding = dimens.toolbar.height + 50

function WidgetPicker(props) {

    const {
        open,
        onOpen = () => {},
        onClose = () => {},
        cols,
        viewCols,
        onRemoveAllUnused = () => {},
    } = props

    const theme = useTheme()
    const alertContext = useContext(AlertContext)

    const allArchiveRef = useRef()

    const [categoryId, setCategoryId] = useState('__archive')
    const showArchive = categoryId === '__archive'
    const neverUsed = categoryId === '__never'

    const handleCategoryChange = (_categoryId) => {
        setCategoryId(_categoryId)
    }

    return (
        <div>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(100% - ${drawerBleeding}px)`,
                        overflow: 'visible',
                    },
                }}
            />

            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={onClose}
                onOpen={onOpen}
                // swipeAreaWidth={drawerBleeding}
                // disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
                PaperProps={{
                    component: Stack,
                    sx: {
                        background: (theme) => theme.palette.mode === 'light' ? '#fff' : theme.palette.primary.light,
                        borderTopRightRadius: '24px',
                        borderTopLeftRadius: '24px',
                        p: 3,
                        boxSizing: 'border-box',
                        overflow: 'auto',
                    }
                }}
            >
                <Stack
                    direction={'row'}
                    spacing={1}
                    flexGrow={1}
                    height={0}
                >
                    <Stack
                        direction={'column'}
                        minWidth={230}
                        maxWidth={350}
                        flexShrink={0}
                        pb={2}
                    >
                        <Typography variant={'caption'} p={1}>
                            {strings.searched_widget}
                        </Typography>

                        <Stack
                            direction={'column'}
                            flexGrow={1}
                            sx={{
                                height: 0,
                                overflow: 'auto',
                            }}
                        >
                            {Object.keys(widgetCategories)?.map((_categoryId, _index) => {
                                const _category = widgetCategories[_categoryId] // todo decoupling
                                return (
                                    <ListItem
                                        key={_index}
                                        id={_categoryId}
                                        icon={_category?.icon} // todo decoupling
                                        text={_category?.name} // todo decoupling
                                        focused={_id => categoryId === _id}
                                        onClick={handleCategoryChange}
                                    />
                                )
                            })}
                        </Stack>

                        <Divider sx={{m: 1}} />

                        <ListItem
                            id={'__archive'}
                            icon={<MaterialIcon>inventory_2</MaterialIcon>}
                            text={strings.archive}
                            focused={_id => categoryId === _id}
                            onClick={handleCategoryChange}
                        />
                        <ListItem
                            id={'__never'}
                            icon={<MaterialIcon>close</MaterialIcon>}
                            text={strings.never_used}
                            focused={_id => categoryId === _id}
                            onClick={handleCategoryChange}
                        />
                        <ListItem
                            id={'__otherProject'}
                            icon={<MaterialIcon>person</MaterialIcon>}
                            text={strings.my_projects}
                            onClick={handleCategoryChange}
                            focused={_id => categoryId === _id}
                        />
                    </Stack>

                    <ScrollableContainer
                        sx={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <SwitchContainer
                            visibleIndex={neverUsed ? 2 : showArchive ? 1 : 0}
                            allGone
                            contents={[
                                <WidgetCategoryGrid categoryId={categoryId} maxCols={cols} />,
                                <WidgetArchive cols={viewCols} filterCols={viewCols} />,
                                <Stack
                                    direction={'column'}
                                    spacing={2}
                                >
                                    <TextButton
                                        height={'32px'}
                                        disableElevation
                                        disabled={!allArchiveRef.current?.hasArchive}
                                        sx={{placeSelf: 'end'}}
                                        onClick={() => alertContext.alert({
                                            title: strings.delete_widget_title,
                                            content: strings.delete_widget_description,
                                            confirmText: strings.delete_all,
                                            confirmColor: 'error',
                                            action: onRemoveAllUnused
                                        })}
                                    >
                                        {strings.delete_all}
                                    </TextButton>
                                    <WidgetArchive ref={allArchiveRef} cols={viewCols} />
                                </Stack>,
                            ]}
                        />
                    </ScrollableContainer>
                </Stack>

                <SearchBox p={1} alignSelf={'center'} width={'100%'} />
                <SwipeThumb />

            </SwipeableDrawer>
        </div>
    )
}

WidgetPicker.propTypes = {
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    cols: PropTypes.number,
    viewCols: PropTypes.number,
    onRemoveAllUnused: PropTypes.func,
}

WidgetPicker.defaultProps = {
}

export default WidgetPicker