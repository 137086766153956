import React, {useContext} from "react";
import PropTypes from "prop-types";
import {
    Stack,
    Typography,
} from "@mui/material";
import Chip from '@mui/material-next/Chip';
import {strings} from "../../values/strings";
import {CssVarsProvider} from "@mui/material-next";
import {AlertContext} from "../../components/AlertDialogProvider";

function Summary(
    {
        countVariables,
        countUnusedVariables,
        onDeleteAllUnusedVariables = () => {},
        sx,
        ...rest
    }
) {
    const alertContext = useContext(AlertContext)

    return (
        <Stack
            direction={'column'}
            spacing={.5}
            py={8}
            alignItems={'center'}
            {...rest}
        >
            <Typography
                variant={'h6'}
                color={'primary.contrastText'}
                noWrap
                align={'center'}
            >
                {strings.n_variables.format(countVariables)}
            </Typography>

            {countUnusedVariables > 0 &&
                <CssVarsProvider>
                    <Chip
                        color={'error'}
                        label={strings.n_unused.format(countUnusedVariables)}
                        onDelete={() => alertContext.alert({
                            title: strings.delete_all_unused_variables,
                            confirmText: strings.delete,
                            confirmColor: 'error',
                            action: onDeleteAllUnusedVariables,
                        })}
                    />
                </CssVarsProvider>
            }
        </Stack>
    )
}

Summary.propTypes = {
    countVariables: PropTypes.number,
    countUnusedVariables: PropTypes.number,
    onDeleteAllUnusedVariables: PropTypes.func,
}

Summary.defaultProps = {
}

export default Summary