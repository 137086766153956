import PropTypes from "prop-types";
import MainToolBar from "./MainToolbar";
import {Outlet} from "react-router-dom";
import React from "react";

function PageContainer(props) {
    const {toolbar, children, style, ...rest} = props;
    return (
        <div
            {...rest}
            style={{
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                position: 'relative',
                ...style
            }}
        >
            {children || <Outlet/>}

            <MainToolBar>
                {Array.isArray(toolbar) ?
                    toolbar.map((_tool, _index) => React.cloneElement(_tool, {key: _index}))
                    : toolbar
                }
            </MainToolBar>
        </div>
    )
}

PageContainer.propTypes = {
    children: PropTypes.any,
    toolbar: PropTypes.any,
}

PageContainer.defaultProps = {
}

export default PageContainer