import React, {useState} from "react";
import PropTypes from "prop-types";
import {styled, Tab, Tabs} from "@mui/material";

const tabHeight = 42

const CustomTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))(({ theme, color }) => ({
    minHeight: `${tabHeight}px !important`,
    border: `2px solid ${color || theme.palette.secondary.main}`,
    borderRadius: 12,
    pointerEvents: 'auto',
    transition: '.2s',
    '& .MuiTabs-indicator': {
        height: `${tabHeight}px !important`,
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        zIndex: 0,
    },
    '& .MuiTabs-indicatorSpan': {
        width: '100%',
        backgroundColor: `${color || theme.palette.secondary.main}`,
        borderRadius: 8,
    },
}));

const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme, color, defaultcolor }) => ({
        textTransform: 'capitalize',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(12),
        color: `${defaultcolor || theme.palette.secondary.main}`,
        minHeight: `${tabHeight}px !important`,
        minWidth: `30px !important`,
        zIndex: 1,
        transition: '.2s',
        '&.Mui-selected': {
            color: color || `${theme.palette.primary.main}`,
        },
        '&.Mui-focusVisible': {
        },
    }),
);

function FilledTabs({value, onChange = (event, newValue) => {}, tabs}) {
    const [colors, setColors] = useState(null)

    const handleChange = (event, newValue) => {
        onChange(event, newValue)

        const item = Array.isArray(tabs) ? tabs?.find(item => item.id === newValue) : null
        if (item.color || item.contrastColor) {
            setColors({
                value: value,
                color: item.color,
                contrast: item.contrast,
            })
        } else {
            setColors(null)
        }
    }

    return (
        <CustomTabs
            value={value}
            onChange={handleChange}
            color={colors?.color}
        >
            {tabs.map((item, index) =>
                <CustomTab
                    key={index}
                    value={item.id || index}
                    label={item.text || item.id || item}
                    color={item.contrastColor}
                    defaultcolor={colors?.color}
                />
            )}
        </CustomTabs>
    )
}

FilledTabs.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    tabs: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.any),
        PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                color: PropTypes.string,
            })
        )
    ]),
}

FilledTabs.defaultProps = {
    tabHeight: 42,
    fontSize: 8,
}

export default FilledTabs