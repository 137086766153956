import React, {createContext, useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {
    calcBoardWidth,
    PageEditContextProvider, ResizableViewContainer,
    WidgetBoard,
} from "@wizlit/react-widget-board";
import {widgetCategories} from "@wizlit/react-widget";
import {SwitchContainer} from "@wizlit/react-component";
import {functions} from "../../data/functions";
import {blocks} from "../../data/blocks";
import {pages} from "../../data/pages";
import dimens from "../../values/dimens";
import PageContainer from "../../containers/PageContainer";
import {Button, Stack, useTheme} from "@mui/material";
import {strings} from "../../values/strings";
import ButtonWithIcon from "../../components/ButtonWithIcon";
import FilledTabs from "../../components/FilledTabs";
import {MaterialIcon} from "../../components/MaterialIcon";
import SidebarContainer from "../../containers/SidebarContainer";
import SidebarContentContainer from "../../containers/SidebarContentContainer";
import {PageSizeIndicator} from "./PageSizeIndicator";
import WidgetPicker from "./WidgetPicker";
import PageProperty from "./PageProperty";
import WidgetProperty from "./WidgetProperty";
import WidgetFunctions from "./WidgetFunctions";
import WidgetToolbar from "./WidgetToolbar";
import VariableManager from "../../subs/VariableManager";

const resizeHandlerSize = 15

const fetchPageById = async (id, callback = (id, page) => {}) => {
    // const response = await fetch(`/api/posts/${postId}`)
    // const data = await response.json()
    // return data

    // return new Promise((resolve) => {
    //     setTimeout(() => {
    //         resolve(pages[id])
    //     }, 2000)
    // })

    if (id) {
        const _page = pages[id]
        callback(id, _page)
    }
}

export const EditPageContext = createContext({
    defaultScenario: null,
    selectedScenario: 'default',
    scenarios: {},
    countVariables: 0,
    countUnusedVariables: 0,

    changeSelectedScenario: (scenario) => {},
    filterVariable: (scenario, dataType) => {},
    createScenario: (name) => {},
    deleteScenario: (scenario) => {},
    renameScenario: (scenario, name) => {},
    changeDefaultScenario: (scenario) => {},
    deleteAllUnusedVariables: () => {},
    editScenario: (scenario, variable, newValue) => {},
    editVariable: (variableId, dataKey, newValue) => {},
})

export const EditPage = (props) => {

    const theme = useTheme()
    const { id: pageId } = useParams()

    const pageRef = useRef()
    const resizeRef = useRef()
    const boardRef = useRef()

    const [id, setId] = useState()
    const [originPage, setOriginPage] = useState()
    const [modifiedPage, setModifiedPage] = useState()

    const [cols, setCols] = useState(8)
    const [actualCols, setActualCols] = useState(8)
    const [size, setSize] = useState()
    const allCols = modifiedPage?.responsive?.map((_item) => _item.cols) || [] // todo decoupling

    const [newOpen, setNewOpen] = useState(false)
    const [isDragging, setIsDragging] = useState(false)
    const [focusedId, setFocusedId] = useState(null)

    const modes = {
        'design': {text: strings.design, index: 0},
        'function': {text: strings.function, index: 1},
        'run': {
            text: strings.run, index: 2,
            color: theme.palette.success.main,
            contrastColor: theme.palette.success.contrastText
        },
    }
    const [mode, setMode] = useState('design') // ['design', 'function']
    const runMode = mode === 'run'

    const scenarios = modifiedPage?.scenarios || {}
    const defaultScenario = scenarios?.default
    const [selectedScenario, setSelectedScenario] = useState('default')

    const variables = modifiedPage?.variables || {}
    const countVariables = Object.keys(variables).length

    /**
     * RESIZE
     */
    const handleBoardResize = (_cols, _actualCols) => {
        setCols(_cols)
        setActualCols(_actualCols)
    }

    const changeCols = (_cols) => {
        resizeRef.current?.updateSize({width: calcBoardWidth(_cols) + 8})
        if (!allCols.includes(_cols)) pageRef.current?.addLayout(_cols)
    }

    const deleteCols = (_cols) => {
        pageRef.current?.removeLayout(_cols)
    }

    /**
     * MODE
     */

    const changeMode = (event, newValue) => {
        setMode(newValue)
    }

    /**
     * FOCUS & DRAG
     */

    const handleFocusChange = (_id) => {
        setFocusedId(_id)
    }

    /**
     * CHANGE
     */

    const onLayoutChange = (_newLayout) => {
        // setOriginPage(_prev => ({..._prev, responsive: _newLayout}))
        setModifiedPage(_prev => ({..._prev, responsive: _newLayout})) // todo decoupling
    }

    const onWidgetChange = (_newLayout) => {
        // setOriginPage(_prev => ({..._prev, widgets: _newLayout}))
        setModifiedPage(_prev => ({..._prev, widgets: _newLayout})) // todo decoupling
    }

    const onScenarioChange = (_newScenario = {}) => {
        setModifiedPage(_prev => ({..._prev, scenarios: _newScenario})) // todo decoupling
    }

    const onVariableChange = (_newVariables) => {
        setModifiedPage(_prev => ({..._prev, variables: _newVariables})) // todo decoupling
    }

    useEffect(() => {
        fetchPageById(pageId, (_id, _page) => {
            setOriginPage(_page)
            setModifiedPage(_page)
            setId(_id)
        })
    }, [id])

    /**
     *
     */

    const context = useMemo(() => ({
        selectedScenario,
        defaultScenario,
        scenarios,
        countVariables,
        // countUnusedVariables,
        changeSelectedScenario: (_scenario) => setSelectedScenario(_scenario),
        filterVariable: pageRef.current?.filterVariable,
        createScenario: pageRef.current?.createScenario,
        deleteScenario: pageRef.current?.removeScenario,
        renameScenario: pageRef.current?.renameScenario,
        changeDefaultScenario: pageRef.current?.changeDefaultScenario,
        editScenario: pageRef.current?.editScenario,
        editVariable: pageRef.current?.editVariable,
        // deleteAllUnusedVariables: ,
    }), [
        // id,
        // originPage,
        modifiedPage,
        // cols,
        // actualCols,
        // size,
        // newOpen,
        // isDragging,
        // focusedId,
        // mode,
        selectedScenario,
        pageRef.current,
    ])

    return (
        <EditPageContext.Provider value={context}>
            <PageContainer
                toolbar={[
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                    >
                        <ButtonWithIcon
                            textColor={theme.palette.primary.contrastText}
                            icon={<MaterialIcon size={24} style={{marginRight: 3}}>publish</MaterialIcon>}
                            // onClick={}
                        >
                            {strings.share}
                        </ButtonWithIcon>

    {/*
                        <ButtonWithIcon
                            textColor={theme.palette.secondary.main}
                            icon={<MaterialIcon size={32}>play_arrow</MaterialIcon>}
                            iconMargin={0}
                            // onClick={}
                        >
                            {strings.run}
                        </ButtonWithIcon>
    */}
                    </Stack>,

                    <FilledTabs
                        value={mode}
                        onChange={changeMode}
                        tabs={Object.entries(modes).map(([id, { text, color }]) => ({ id, text, color }))}
                    />
                ]}
            >
                <Stack
                    sx={{
                        position: 'fixed',
                        width: '100%',
                        minHeight: dimens.toolbar.height,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        variant={'contained'}
                        color={'secondary'}
                        disableElevation
                        disableRipple
                        disabled={runMode}
                        onClick={() => {
                            boardRef.current?.fixDrag()
                            setNewOpen(true)
                        }}
                        sx={{
                            width: 150,
                            borderRadius: 10,
                            height: 50,
                            px: 3,
                        }}
                    >
                        {strings.add_widget}
                    </Button>
                </Stack>

                {modifiedPage &&
                    <PageEditContextProvider
                        ref={pageRef}
                        categories={widgetCategories}
                        sharedFunctions={functions}
                        blocks={blocks}
                        defaultPageProperty={modifiedPage}
                        scenario={selectedScenario}
                        onLayoutChange={onLayoutChange}
                        onWidgetChange={onWidgetChange}
                        onScenarioChange={onScenarioChange}
                        onVariableChange={onVariableChange}
                        developerMode
                    >
                        <WidgetPicker
                            // open
                            open={newOpen && !isDragging}
                            onOpen={() => setNewOpen(true)}
                            onClose={() => setNewOpen(false)}
                            cols={actualCols}
                            viewCols={cols}
                            onRemoveAllUnused={boardRef.current?.removeUnusedWidgets}
                        />

                        <Stack
                            direction={'row'}
                            spacing={1}
                            style={{
                                height: '100%',
                                paddingTop: dimens.toolbar.height,
                                paddingBottom: dimens.sidebar.margin,
                                paddingRight: dimens.sidebar.margin,
                                boxSizing: 'border-box',
                            }}
                        >
                            <PageSizeIndicator
                                colList={allCols}
                                cols={cols}
                                actualCols={actualCols}
                                onChange={changeCols}
                                onDelete={deleteCols}
                            />

                            <div
                                style={{
                                    flexGrow: 1,
                                    height: `calc(100% + ${(resizeHandlerSize + 5) * 2}px)`,
                                    position: 'relative',
                                    overflow: 'hidden',
                                    marginTop: `-${resizeHandlerSize + 5}px`,
                                }}
                            >
                                <ResizableViewContainer
                                    ref={resizeRef}
                                    alignCenter
                                    handlerSpacing={resizeHandlerSize}
                                    contentStyle={{
                                        background: 'white',
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: 'rgba(0,0,0,0.5)',
                                        },
                                    }}
                                    minWidth={calcBoardWidth(1) + 8}
                                    size={size}
                                    setSize={setSize}
                                    // controller={(width, height, scale, onChange, onScaleChange) => }
                                    // disableController
                                    // onClick={boardRef.current?.loseFocus}
                                >
                                    <WidgetBoard
                                        ref={boardRef}
                                        // developerMode
                                        centering
                                        onColsChange={handleBoardResize}
                                        onFocusChange={handleFocusChange}
                                        onDragStateChange={setIsDragging}
                                    />
                                </ResizableViewContainer>
                            </div>

                            <SidebarContainer
                                fixed={false}
                                overlay={<VariableManager />}
                            >
                                <SwitchContainer
                                    visibleIndex={modes[mode]?.index}
                                    // goneIndexes={}
                                    // allGone={}
                                    contents={[
                                        <SwitchContainer
                                            visibleIndex={focusedId ? 1 : 0}
                                            // goneIndexes={}
                                            // allGone={}
                                            contents={[
                                                <PageProperty/>,
                                                <WidgetProperty
                                                    toolbar={
                                                        <WidgetToolbar
                                                            id={focusedId}
                                                            cancelClick={boardRef.current?.loseFocus}
                                                            archiveClick={boardRef.current?.archiveWidget}
                                                            deleteClick={boardRef.current?.removeWidget}
                                                        />
                                                    }
                                                />,
                                            ]}
                                        />,

                                        <SwitchContainer
                                            visibleIndex={focusedId ? 1 : 0}
                                            // goneIndexes={}
                                            // allGone={}
                                            contents={[
                                                <SidebarContentContainer
                                                    defaultIndex={0}
                                                    tabs={[
                                                        {
                                                            title: strings.function,
                                                        },
                                                    ]}
                                                />,
                                                <WidgetFunctions
                                                    toolbar={
                                                        <WidgetToolbar
                                                            id={focusedId}
                                                            cancelClick={boardRef.current?.loseFocus}
                                                            archiveClick={boardRef.current?.archiveWidget}
                                                            deleteClick={boardRef.current?.removeWidget}
                                                        />
                                                    }
                                                />,
                                            ]}
                                        />,

                                        <SidebarContentContainer
                                            defaultIndex={0}
                                            tabs={[
                                                {
                                                    title: strings.deploy,
                                                },
                                            ]}
                                        />

                                    ]}
                                />
                            </SidebarContainer>

                        </Stack>

                    </PageEditContextProvider>
                }
            </PageContainer>
        </EditPageContext.Provider>
    )
}