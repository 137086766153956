import React, {useContext, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {Stack, Tooltip, useTheme} from "@mui/material";
import {MaterialIcon} from "../../components/MaterialIcon";
import {strings} from "../../values/strings";
import {AlertContext} from "../../components/AlertDialogProvider";

const Indicator = ({cols, selected, isTemp, onClick, disabled, ...rest}) => {
    const theme = useTheme()
    const ref = useRef()

    const [isHovered, setIsHovered] = useState(false)
    const [state, setState] = useState({})

    useEffect(() => {
        if (selected) {
            ref.current?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [selected])

    useEffect(() => {
        let _styles = {}

        if (selected) {
            _styles = {
                borderSize: 3,
                borderColor: theme.palette.secondary.main,
                color: theme.palette.secondary.main,
            }

            if (disabled) {
                if (isHovered) {
                    _styles = {
                        ..._styles,
                        icon: <MaterialIcon>add</MaterialIcon>
                    }
                } else {
                    _styles = {
                        ..._styles,
                        opacity: .2
                    }
                }
            } else if (isHovered) {
                _styles = {
                    borderSize: 3,
                    borderColor: theme.palette.error.main,
                    color: theme.palette.error.main,
                    icon: <MaterialIcon>delete</MaterialIcon>
                }
            }
        } else if (isTemp) {
            _styles = {
                borderColor: `${theme.palette.primary.contrastText}90`,
            }
        }

        setState(_styles)
    }, [selected, isTemp, isHovered, disabled])

    return (
        <Stack
            ref={ref}
            direction={'row'}
            onClick={onClick}
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            {...rest}
            style={{
                color: state.color || theme.palette.primary.contrastText,
                border: `${state.borderSize || .1}px solid ${state.borderColor || 'transparent'}`,
                background: theme.palette.primary.main,
                borderRadius: 5,
                width: 70,
                minHeight: 70,
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                boxSizing: 'border-box',
                opacity: state.opacity || 1,
                transition: '.1s',
                ...rest.style
            }}
        >
            {state.icon || cols}
        </Stack>
    )
}

const Label = ({icon, text, color, background, hint}) => {
    const theme = useTheme()
    return (
        <Tooltip
            title={hint}
            placement={'right'}
            slotProps={{
                tooltip: {
                    sx: {
                        fontSize: 14,
                        wordBreak: "keep-all",
                        whiteSpace: "pre-line",
                        lineHeight: 1.5,
                        letterSpacing: 0.2
                    }
                }
            }}
        >
            <Stack
                direction={'column'}
                spacing={1}
                sx={{
                    ...theme.typography.caption,
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: color || theme.palette.primary.contrastText,
                    textTransform: 'capitalize',
                    background,
                    borderRadius: 2,
                    p: background ? 1 : 0,
                    cursor: 'help',
                }}
            >
                {icon}
                <div>{text}</div>
            </Stack>
        </Tooltip>
    )
}

export const PageSizeIndicator = (props) => {

    const {
        colList = [],
        cols,
        actualCols,
        onChange = (cols) => {},
        onDelete = (cols) => {},
        ...rest
    } = props

    const theme = useTheme()
    const alertContext = useContext(AlertContext)

    const ref = useRef()
    const [hasOverflow, setOverflow] = useState(false)

    const handleClick = (_cols) => {
        if (cols === actualCols && actualCols === _cols) {
            alertContext.alert({
                content: strings.delete_size,
                confirmText: strings.delete,
                confirmColor: 'error',
                action: () => onDelete(_cols)
            })
        }
        else onChange(_cols)
    }

    useEffect(() => {
        if (ref.current) {
            const hasOverflowing = ref.current.offsetHeight < ref.current.scrollHeight || ref.current.offsetWidth < ref.current.scrollWidth
            setOverflow(hasOverflowing)
        }
    }, [ref.current])

    return (
        <div
            {...rest}
            style={{
                height: '100%',
                minWidth: 100,
                flexBasis: 100,
                position: 'relative',
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
                overflow: 'hidden',
                ...rest.style
            }}
        >
            <Stack
                ref={ref}
                direction={'column'}
                spacing={2}
                sx={{
                    height: '100%',
                    px: 1.5,
                    py: 2,
                    alignItems: 'center',

                    transition: ".2s",
                    boxSizing: 'border-box',
                    boxShadow: "0px 0px 24px #00000020",
                    overflow: 'auto',
                    background: theme.palette.primary.light,
                    userSelect: "none",
                    msUserSelect: "none",
                    MozUserSelect: "none",
                    WebkitUserSelect: "none",
                    '&::-webkit-scrollbar': {
                        width: 0
                    },
                }}
            >
                <Label
                    icon={<MaterialIcon>aspect_ratio</MaterialIcon>}
                    text={strings.page_size}
                    hint={strings.page_size_describe}
                />

                {[...new Set([...colList, actualCols])].sort((a, b) => b - a).map((_cols, _index) =>
                    <Indicator
                        key={_index}
                        cols={_cols}
                        selected={_cols === actualCols}
                        isTemp={cols === _cols}
                        disabled={!colList.includes(_cols)}
                        onClick={() => handleClick(_cols)}
                    />
                )}
            </Stack>

{/*
            {hasOverflow &&
                <Box
                    position={"absolute"}
                    top={0}
                    left={0}
                    right={0}
                    style={{
                        background: `linear-gradient(0deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.light} 10%, ${theme.palette.primary.light}00 100%)`,
                        pointerEvents: "none",
                    }}
                    height={5 * 8 * 2 + 16}
                />
            }
*/}
        </div>
    )
}

PageSizeIndicator.propTypes = {
    cols: PropTypes.number,
    recommendCols: PropTypes.number,
    colList: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
}

PageSizeIndicator.defaultProps = {
}