import {Link} from "react-router-dom";
import {ThemeProvider, Typography} from "@mui/material";
import PropTypes from "prop-types";
import logoTheme from "../values/theme/logo";

function Logo(props) {
    const { size = 36, onClick, to = '/' } = props;

    const linkToMain = (c) => {
        if (onClick) return c;
        return c
        // return (
        //     <Link to={to} style={{textDecoration: "none"}}>
        //         {c}
        //     </Link>
        // )
    }

    return (
        <ThemeProvider theme={logoTheme}>
            {linkToMain(
                <Typography
                    width={"auto"}
                    color={"secondary"}
                    align={"center"}
                    mx={"auto"}
                    sx={{
                        font: `normal normal normal ${size}px Dancing Script`,
                        letterSpacing: "0px",
                        userSelect: "none",
                        msUserSelect: "none",
                        MozUserSelect: "none",
                        WebkitUserSelect: "none",
                        cursor: "pointer",
                        pointerEvents: 'auto',
                    }}
                    onDragStart={e => e.preventDefault()}
                    onClick={onClick}
                >
                    Wizlit
                </Typography>
            )}
        </ThemeProvider>
    )
}

Logo.propTypes = {
    size: PropTypes.number,
    onClick: PropTypes.func
}

export default Logo;