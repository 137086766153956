const strings = {
    logo_name: "Wizlit",
    share: 'share',
    run: 'run',
    design: 'design',
    function: 'function',
    property: 'property',
    property_short: 'props',
    component: 'component',
    theme: 'theme',
    page_size: 'page size',
    download: 'download',
    color: 'color',
    input: 'input',
    variable: 'variable',
    deploy: 'deploy',
    delete_size: 'delete size',
    delete: 'delete',
    cancel: 'cancel',
    add_widget: 'add widget',
    default: 'default',
    archive: 'archive',
    this_project: 'this project',
    my_projects: 'my projects',
    show_all_n_variables: 'show all {0} variables',
    n_unused: '{0} unused',
    n_variables: '{0} variables',
    page_size_describe: 'Page size differ in screen size\n' +
        '\n- Desktop: 20 ~ 10' +
        '\n- Tablet: 10 ~ 6' +
        '\n- Mobile: 6 ~ 4',
    delete_widget_title: 'Are you sure you want to delete the widget?',
    delete_widget_description: 'The widget will be completely deleted from all page sizes',
    archive_widget_title: 'Do you want to archive the widget?',
    archive_widget_description: '- The widget will not be deleted\n- It will only disappear from the current page size',
    searched_widget: 'searched widget',
    never_used: 'never used',
    delete_all: 'delete all',
    set_as_default: 'set as default',

    any: 'any',
    bool: 'bool',
    list: 'list',
    date: 'date',
    text: 'text',
    file: 'file',
    select_file: 'select file',

    add: 'add',
    create_new_scenario: 'create new scenario',
    create: 'create',
    scenario_name: 'scenario name',
    delete_scenario: 'delete scenario',
    rename: 'rename',
    rename_scenario: 'rename scenario',
    delete_all_unused_variables: 'delete all unused variables',
    do_not_put_sensitive_information: 'do not put sensitive information',
    variable_name: 'variable name',
    variable_description: 'variable description',
    variable_value: 'variable value',
    close: 'close',
    change_view: 'change view',


    // wiz_name: "wiz name",
    // wiz_invite: "wiz invite",
    // joined_wiz: "joined wiz",
    // event_invite: "event invite",
    // notification: "notification",
    // about_wiz: "about wiz",
    // wiz_invite_description: "Wiz invites will automatically removed after 1 week",
    // system_language: "System Language",
    // read_all: "Read all",
    // no_invites: "No Invites",
    // no_notification: "No notification",
    // accept: "accept",
    // joined_wiz_description: "New wiz notification shows up here",
    // event_detail: "event detail",
    // ready_event: "Event In Ready",
    // no_events: "No Events",
    // no_notices: "No Notices",
    // post: "Post",
    // no_posts: "No Post",
    // join_request: "Join Request",
    // no_requests: "No Requests",
    // no_members: "No Members",
    // second_short: "S",
    // minute_short: "M",
    // hour_short: "H",
    // day_short: "D",
    // month_short: "M",
    // year_short: "Y",
    // notice: "Notice",
    // event: "Event",
    // member: "Member",
    // request: "Request",
    // all: "All",
    // new: "New",
    // wizlit_event_description: "Tried of Notifications? Experience the new way",
    // participated: "participated",
    // post_detail: "post detail",
    // reject: "reject",
    // join_request_description: "There are people who want to join to your wiz",
    // detail: "detail",
    // role: "role",
    // join_date: "join date",
    // move_to_event_page: "move to event page",
    // participant: "participant",
    // kick: "kick",
    // me: "me",
    // search_user_to_invite: "Search user to invite",
    // about: "about",
    // private_post: "Private post",
    // unknown_post: "Unknown post",
    // new_post: "New post",
    // public: "Public",
    // private: "Private",
    // action_post: "Post",
    // untitled: "Untitled",
    // write_something_here: "Write something here",
    // cancel: "Cancel",
    // ok: "Ok",
    // discard_changes: "Discard Changes",
    // discard: "Discard",
    // changes_you_made_may_not_be_saved: "Changes you made may not be saved",
    // drop_here: "Drop here",
    // create_wiz: "Create wiz",
    // pending_create_wiz: "Pending to create wiz: '{0}'",
    // creating_wiz: "Wiz Creating... '{0}'",
    // created_wiz: "Wiz Created: '{0}'",
    // enter_your_wiz_name: "Name your wiz ...",
    // move: "Move",
    // move_to_wiz_page: "Move to wiz page",
    // failed_to_receive_data: "Failed to receive data",
    // name_starts_with_special_character: "Name starts with special character",
    // name_minimum_length_error: "Name should be minimum of {0}",
    // name_maximum_length_error: "Name should be maximum of {0}",
    // close: "close",
    // background_processing_title: "{0} out of {1} completed",
    // event_title: "Event title",
    // event_description: "tell the others what your event is all about",
    // description: "description",
    // create_event: "Create event",
    // end_date_is_smaller_than_start_date: "End date is smaller than start date",
    // event_should_be_longer_than_n_minutes: "Event should be longer than {0} minutes",
    // event_should_be_no_longer_than_n_minutes: "Event should be no longer than {0} minutes",
    // pending_create_event: "Pending to create event: '{0}'",
    // creating_event: "Event Creating... '{0}'",
    // created_event: "Event Created: '{0}'",
    // event_title_min_length_error_message: "Event title length must be more than {0} ",
    // event_title_max_length_error_message: "Event title length must be less than {0} ",
    // event_description_max_length_error_message: "Event description length must be less than {0} ",
    // title_starts_with_special_character: "Title starts with special character",
    // start_time_should_be_greater_than_now: "Start time should be greater than now",
}

export const EN = strings;