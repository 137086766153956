// f3f0245d-43eb-4015-a9c5-c22def264e87
// 67d062d0-337a-4bbb-857b-b9d10a6f28a6
// 92b0ef23-e50f-4b76-af35-085bc2033bc7
// 6dd18cd1-266d-41a1-a125-5c29149a88be

export const page2 = {
    theme: {
        mode: 'light',
        light: {
            background: '',
            widget: '',
        },
        dark: {
            background: '',
            widget: '',
        },
    },
    scenarios: {
        default: 'initial',
        'initial': {
            name: '초기',
            variables: {
            },
        },
    },
    variables: {
        '__url__': { title: '변수명', description: '변수 설명' },
        '__start_date__': { title: '변수명', description: '변수 설명' },
        '__end_date__': { title: '변수명', description: '변수 설명' },
        '__date_range_parts__': { title: '변수명', description: '변수 설명' },
        '__weights__': { title: '변수명', description: '변수 설명' },
        '__weights_original__': { title: '변수명', description: '변수 설명' },
        '__number_of_weeks__': { title: '변수명', description: '변수 설명' },
        '__transaction_quantity_total__': { title: '변수명', description: '변수 설명' },
        '__transaction_percentage__': { title: '변수명', description: '변수 설명' },
        '__transaction_quantity_total_original__': { title: '변수명', description: '변수 설명' },
        '__transaction_percentage_original__': { title: '변수명', description: '변수 설명' },
        '__fetching__': { title: '변수명', description: '변수 설명' },
        '__is_w1__': { title: '변수명', description: '변수 설명' },
        '__has_changes__': { title: '변수명', description: '변수 설명' },
    },
    functions: {
        'jghmghndfgbhdf': {
            title: '시뮬레이션 데이터 가져오기',
            functionId: 'haSDFGSDAF',
            functionInputs: {
                var1: '__fetching__',
                var2: '__url__',
                var3: '__start_date__',
                var4: '__transaction_quantity_total__',
                var5: '__transaction_quantity_total_original__',
                var6: '__transaction_percentage__',
                var7: '__transaction_percentage_original__',
                var8: '__weights__',
                var9: '__weights_original__',
                var10: '__date_range_parts__',
                var11: '__number_of_weeks__',
                var12: '__is_w1__',
                var13: '__end_date__',
                var14: '__has_changes__',
            }
        },
        'bh5brgebgdfs': {
            title: '수정',
            functionId: 'jkghfgsfg',
            functionInputs: {
                var1: '__has_changes__',
                var2: '__transaction_quantity_total__',
                var3: '__transaction_quantity_total_original__',
                var4: '__transaction_percentage__',
                var5: '__transaction_percentage_original__',
                var6: '__weights__',
                var7: '__weights_original__',
            }
        },
        'm6bfgberg': {
            title: '되돌리기',
            functionId: 'gagasdfasdfsd',
            functionInputs: {
                var1: '__has_changes__',
                var2: '__transaction_quantity_total__',
                var3: '__transaction_quantity_total_original__',
                var4: '__transaction_percentage__',
                var5: '__transaction_percentage_original__',
                var6: '__weights__',
                var7: '__weights_original__',
            }
        },
        'ghfgdfgadf': {
            title: '메인 페이지로 돌아가기',
            functionId: 'vsdfasdfasdf',
            functionInputs: {
                var1: '__url__',
                var2: '__start_date__',
                var3: '__transaction_quantity_total__',
                var4: '__transaction_percentage__',
                var5: '__weights__',
            }
        },
        'gafgasdgfasdf': {
            title: '메인 페이지로 돌아가기',
            functionId: 'vsdfasdfafgfgsdf',
            functionInputs: {
                var1: '__url__',
            }
        },
    },
    // events: {
    //     onStart: 'vafnj9njiosdvfni',
    // },
    widgets: {
        '6dd18cd1-266d-41a1-a125-5c29149da88be': {
            categoryId: 'dashboardEditButtonGroup',
            inputs: {
                cancelText: '취소',
                confirmText: '미리보기 및 조회',
                confirmColor: '#000',
                // loading: true,
                align: 'right',
                hasChanges: '__has_changes__',
            },
            events: {
                resetClick: 'm6bfgberg',
                cancelClick: 'gafgasdgfasdf',
                confirmClick: 'ghfgdfgadf',
            },
            noShadow: true,
        },
        'f3f0245d-43eb-4015-a9c5-c22def264e87': {
            categoryId: 'dateRangePicker',
            inputs: {
                color: '#9e0025',
                startDate: '__start_date__', // '2023-12-01',
                endDate: '__end_date__', // '2024-01-01',
                badgeText: '__date_range_parts__', // 'W1',
                rangePosition: 'start',
                disableWeekends: true,
                disableFuture: true,
            },
            events: {
                onChange: 'jghmghndfgbhdf',
            },
        },
        '67d062d0-337a-4bbb-857b-b9d10a6f28a6': {
            categoryId: 'rankWeightModify',
            inputs: {
                title: '가중치 선택',
                selectPlaceholder: '추천 가중치 선택',
                labels: ['1순위', '2순위', '3순위', '4순위'],
                recommends: [
                    [75, 25, 0, 0],
                    [70, 30, 0, 0],
                    [50, 25, 15, 10],
                    [50, 30, 20, 0],
                    [40, 20, 20, 20],
                    [75, 25],
                    [70, 30],
                    [60, 40],
                ],
                values: '__weights__', // [70, 20, 10, 0],
                valuesCount: '__number_of_weeks__', // 4,
            },
            events: {
                onChange: 'bh5brgebgdfs',
            },
            loading: '__fetching__',
        },
        '92b0ef23-e50f-4b76-af35-085bc2033bc7': {
            categoryId: 'transactionQuantityModify',
            inputs: {
                title: '거래 물량',
                prefix: '$',
                total: '__transaction_quantity_total__', // 150000
                percentage: '__transaction_percentage__', // 20
                primaryLabel: '균등',
                secondaryLabel: '모델',
                primaryColor: '#d9d9d9',
                secondaryColor: '#ffcdcd',
            },
            events: {
                totalChange: 'bh5brgebgdfs',
                percentageChange: 'bh5brgebgdfs',
            },
            loading: '__fetching__',
        },
    },
    responsive: [
        {
            cols: 13,
            layout: [
                { id: 'f3f0245d-43eb-4015-a9c5-c22def264e87', x: 0, y: 0 },
                { id: '6dd18cd1-266d-41a1-a125-5c29149da88be', x: 10, y: 0 },
                { id: '67d062d0-337a-4bbb-857b-b9d10a6f28a6', x: 10, y: 5 },
                { id: "92b0ef23-e50f-4b76-af35-085bc2033bc7", x: 10, y: 2 },
            ],
            types: {
                '6dd18cd1-266d-41a1-a125-5c29149da88be': 0,
                'f3f0245d-43eb-4015-a9c5-c22def264e87': 0,
                '67d062d0-337a-4bbb-857b-b9d10a6f28a6': 0,
                '92b0ef23-e50f-4b76-af35-085bc2033bc7': 0,
            }
        },
        {
            cols: 12,
            layout: [
                { id: 'f3f0245d-43eb-4015-a9c5-c22def264e87', x: 0, y: 0 },
                { id: '6dd18cd1-266d-41a1-a125-5c29149da88be', x: 9, y: 0 },
                { id: '67d062d0-337a-4bbb-857b-b9d10a6f28a6', x: 9, y: 5 },
                { id: "92b0ef23-e50f-4b76-af35-085bc2033bc7", x: 9, y: 2 },
            ],
            types: {
                '6dd18cd1-266d-41a1-a125-5c29149da88be': 0,
                'f3f0245d-43eb-4015-a9c5-c22def264e87': 0,
                '67d062d0-337a-4bbb-857b-b9d10a6f28a6': 0,
                '92b0ef23-e50f-4b76-af35-085bc2033bc7': 0,
            }
        },
        {
            cols: 11,
            layout: [
                { id: 'f3f0245d-43eb-4015-a9c5-c22def264e87', x: 0, y: 0 },
                { id: '6dd18cd1-266d-41a1-a125-5c29149da88be', x: 8, y: 0 },
                { id: '67d062d0-337a-4bbb-857b-b9d10a6f28a6', x: 8, y: 5 },
                { id: "92b0ef23-e50f-4b76-af35-085bc2033bc7", x: 8, y: 2 },
            ],
            types: {
                '6dd18cd1-266d-41a1-a125-5c29149da88be': 0,
                'f3f0245d-43eb-4015-a9c5-c22def264e87': 0,
                '67d062d0-337a-4bbb-857b-b9d10a6f28a6': 0,
                '92b0ef23-e50f-4b76-af35-085bc2033bc7': 0,
            }
        },
        {
            cols: 10,
            layout: [
                { id: 'f3f0245d-43eb-4015-a9c5-c22def264e87', x: 0, y: 0 },
                { id: '6dd18cd1-266d-41a1-a125-5c29149da88be', x: 7, y: 0 },
                { id: '67d062d0-337a-4bbb-857b-b9d10a6f28a6', x: 7, y: 5 },
                { id: "92b0ef23-e50f-4b76-af35-085bc2033bc7", x: 7, y: 2 },
            ],
            types: {
                '6dd18cd1-266d-41a1-a125-5c29149da88be': 0,
                'f3f0245d-43eb-4015-a9c5-c22def264e87': 0,
                '67d062d0-337a-4bbb-857b-b9d10a6f28a6': 0,
                '92b0ef23-e50f-4b76-af35-085bc2033bc7': 0,
            }
        },
        {
            cols: 9,
            layout: [
                { id: 'f3f0245d-43eb-4015-a9c5-c22def264e87', x: 0, y: 0 },
                { id: '6dd18cd1-266d-41a1-a125-5c29149da88be', x: 6, y: 0 },
                { id: '67d062d0-337a-4bbb-857b-b9d10a6f28a6', x: 6, y: 5 },
                { id: "92b0ef23-e50f-4b76-af35-085bc2033bc7", x: 6, y: 2 },
            ],
            types: {
                '6dd18cd1-266d-41a1-a125-5c29149da88be': 0,
                'f3f0245d-43eb-4015-a9c5-c22def264e87': 0,
                '67d062d0-337a-4bbb-857b-b9d10a6f28a6': 0,
                '92b0ef23-e50f-4b76-af35-085bc2033bc7': 0,
            }
        },
        {
            cols: 8,
            layout: [
                { id: 'f3f0245d-43eb-4015-a9c5-c22def264e87', x: 0, y: 0 },
                { id: '6dd18cd1-266d-41a1-a125-5c29149da88be', x: 5, y: 0 },
                { id: '67d062d0-337a-4bbb-857b-b9d10a6f28a6', x: 5, y: 5 },
                { id: "92b0ef23-e50f-4b76-af35-085bc2033bc7", x: 5, y: 2 },
            ],
            types: {
                '6dd18cd1-266d-41a1-a125-5c29149da88be': 0,
                'f3f0245d-43eb-4015-a9c5-c22def264e87': 0,
                '67d062d0-337a-4bbb-857b-b9d10a6f28a6': 0,
                '92b0ef23-e50f-4b76-af35-085bc2033bc7': 0,
            }
        },
        {
            cols: 7,
            layout: [
                { id: 'f3f0245d-43eb-4015-a9c5-c22def264e87', x: 0, y: 0 },
                { id: '6dd18cd1-266d-41a1-a125-5c29149da88be', x: 4, y: 0 },
                { id: '67d062d0-337a-4bbb-857b-b9d10a6f28a6', x: 4, y: 5 },
                { id: "92b0ef23-e50f-4b76-af35-085bc2033bc7", x: 4, y: 2 },
            ],
            types: {
                '6dd18cd1-266d-41a1-a125-5c29149da88be': 0,
                'f3f0245d-43eb-4015-a9c5-c22def264e87': 0,
                '67d062d0-337a-4bbb-857b-b9d10a6f28a6': 0,
                '92b0ef23-e50f-4b76-af35-085bc2033bc7': 0,
            }
        },
    ],
}