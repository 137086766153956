import React from "react";
import PropTypes from "prop-types";

export const MaterialIcon = (props) => {
    const {children, size, weight, grade, fill, style, ...rest} = props

    return (
        <span
            className="material-symbols-rounded"
            {...rest}
            style={{
                fontVariationSettings: `'FILL' ${fill ? 1 : 0}, 'wght' ${weight}, 'GRAD' ${grade}, 'opsz' 48`,
                fontSize: size,
                ...style
            }}
        >
            {children}
        </span>
    )
}

MaterialIcon.propTypes = {
    children: PropTypes.string,
    size: PropTypes.number,
    weight: PropTypes.number,
    grade: PropTypes.number,
    fill: PropTypes.bool,
}

MaterialIcon.defaultProps = {
    children: 'arrow_right_alt',
    size: 24,
    weight: 200,
    grade: 0,
    fill: false,
}