import React, {useState} from "react";
import PropTypes from "prop-types";
import {Stack, useTheme} from "@mui/material";
import {SwitchContainer} from "@wizlit/react-component";
import UnderbarTabs from "../../components/UnderbarTabs";
import ScrollableContainer from "../../components/ScrollableContainer";
import MiniToolbar from "../../components/MiniToolbar";

function SidebarContentContainer(props) {
    const {children, defaultIndex, tabs, toolbar, ...rest} = props
    const theme = useTheme()

    const [active, setActive] = useState(defaultIndex)

    const handleTab = (event, newValue) => {
        setActive(newValue);
    }

    const onlyTabTitles = tabs?.map(tab => tab.title)

    return (
        <Stack direction={'column'} height={'100%'} position={'relative'}>
            {toolbar && <MiniToolbar>{toolbar}</MiniToolbar>}

            <UnderbarTabs
                value={active}
                onChange={handleTab}
                tabs={onlyTabTitles}
                sx={{
                    pt: toolbar ? 0 : 2,
                    pb: 1,
                    px: 2,
                    // position: 'sticky',
                    // top: 0,
                    background: theme.palette.primary.light,
                    zIndex: 1,
                }}
            />

            <SwitchContainer
                contents={tabs?.map((tab, index) =>
                    <ScrollableContainer key={index} hideScrollbar height={'100%'} >
                        {tab.layout || <></>}
                    </ScrollableContainer>
                )}
                visibleIndex={active}
            />

            {children}
        </Stack>
    )
}

SidebarContentContainer.propTypes = {
    children: PropTypes.any,
    defaultIndex: PropTypes.number,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            layout: PropTypes.any,
            gone: PropTypes.bool,
        })
    ),
    toolbar: PropTypes.any,
}

SidebarContentContainer.defaultProps = {
    defaultIndex: 0
}

export default SidebarContentContainer