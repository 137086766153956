import {useEffect} from "react";
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {Page} from "./pages/Page";
import {EditPage} from "./pages/EditPage";
import PageContainer from "./containers/PageContainer";

function App() {

    useEffect(() => {
        document.body.style.background = "#1F2428";
        document.body.style.transition = ".3s";
    }, [])

    return (
        <Routes>
            <Route path={'/d'} element={<Outlet />}>
                <Route index element={<></>} />
                <Route path={`page/:id`} element={<EditPage />} />
            {/*    <Route path={`/:id`} element={<Page />} />*/}
            </Route>
            {/*<Route path="*" element={<Navigate to="/page/main?__url__=http://127.0.0.1:9102" replace />} />*/}
        </Routes>
    );
}

export default App;
