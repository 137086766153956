import colors from "../colors";
import {createTheme} from "@mui/material/styles";
import {DarkModeRounded, LightModeRounded} from "@mui/icons-material";

const defaultTheme = {
    typography: {
        fontFamily: ['Sen', 'sans-serif'].join(','),
    },
    palette: {
        mode: "dark",
        background: {
            default: colors.tertiaryMain,
            paper: colors.tertiaryMain
        },
        primary: {
            main: colors.primaryMain,
            light: colors.primaryLight,
            dark: colors.primaryDark,
            contrastText: colors.primaryContrastText
        },
        secondary: {
            main: colors.secondaryMain,
            light: colors.secondaryLight,
            dark: colors.secondaryDark,
            contrastText: colors.secondaryContrastText
        },
        text: {
            primary: colors.textPrimary,
            secondary: colors.textSecondary,
            disabled: colors.textDisabled,
        },
        info: {
            main: colors.infoMain,
            light: colors.infoLight,
            dark: colors.infoDark,
            contrastText: colors.infoContrastText
        },
        success: {
            main: colors.successMain,
            light: colors.successLight,
            dark: colors.successDark,
            contrastText: colors.successContrastText
        },
        warning: {
            main: colors.warningMain,
            light: colors.warningLight,
            dark: colors.warningDark,
            contrastText: colors.warningContrastText
        },
        error: {
            main: colors.errorMain,
            light: colors.errorLight,
            dark: colors.errorDark,
            contrastText: colors.errorContrastText
        },
    },
};

export const darkTheme = createTheme({
    ...defaultTheme,
    icon: (props) => <DarkModeRounded {...props} />
});

export const lightTheme = createTheme({
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        mode: "light",
        background: {
            default: "#ffffff",
            paper: "#ffffff"
        },
        primary: {
            main: "#f6f6f6",
            light: "#ffffff",
            dark: "#d9d9d9",
            contrastText: "#000000"
        },
    },
    icon: (props) => <LightModeRounded {...props} />
});
