import {TextField, useTheme} from "@mui/material";
import React from "react";

function TextArea(props) {
    const theme = useTheme();
    return (
        <TextField
            variant={'standard'}
            color={'secondary'}
            autoComplete={'off'}
            multiline
            {...props}
            style={{
                maxHeight: 300,
                overflowY: 'auto',
                boxSizing: 'border-box',
                border: `1px solid ${theme.palette.primary.contrastText}2A`,
                backgroundColor: theme.palette.primary.main,
                borderRadius: 8,
                ...props.style,
            }}
            InputProps={{
                disableUnderline: true,
                ...props.InputProps,
            }}
            inputProps={{
                ...props.inputProps,
                style: {
                    padding: 8,
                    fontSize: 14,
                    ...props.inputProps?.style
                },
            }}
        />
    )
}

export default TextArea