import React from "react";
import PropTypes from "prop-types";
import {strings} from "../../values/strings";
import SidebarContentContainer from "../../containers/SidebarContentContainer";
import {useTheme} from "@mui/material";

function WidgetFunctions(props) {

    const {
        toolbar
    } = props

    const theme = useTheme()

    return (
        <SidebarContentContainer
            defaultIndex={0}
            toolbar={toolbar}
            tabs={[
                {
                    title: strings.function,
                },
            ]}
        />
    )
}

WidgetFunctions.propTypes = {
    toolbar: PropTypes.any,
}

WidgetFunctions.defaultProps = {
}

export default WidgetFunctions