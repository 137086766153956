import {Button, useTheme} from "@mui/material";

function ButtonWithIcon(
    {
        variant = 'text',
        textColor,
        icon,
        startIcon,
        endIcon,
        iconMargin = 3,
        ...props
    }
) {
    const {children} = props;
    const theme = useTheme();
    return (
        <Button
            variant={variant}
            color={'inherit'}
            startIcon={icon || startIcon}
            endIcon={endIcon}
            disableTouchRipple
            disableFocusRipple
            disableElevation
            {...props}
            sx={{
                height: 44,
                color: textColor,
                px: 1.2,
                lineHeight: 1,
                pointerEvents: 'auto',
                borderRadius: '8px',
                '& .MuiButton-startIcon': {
                    marginRight: `${iconMargin}px`
                },
                '& .MuiButton-endIcon': {
                    marginLeft: `${iconMargin}px`
                },
                ...props.sx,
            }}
        >
            {children}
        </Button>
    )
}

export default ButtonWithIcon