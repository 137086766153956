import PropTypes from "prop-types";
import {Stack, Typography, useTheme} from "@mui/material";

function ListItem(props) {

    const {
        id,
        icon,
        text,
        onClick = (id) => {},
        focused: defaultFocused,
    } = props

    const theme = useTheme()

    const focused = typeof defaultFocused === 'function' ? defaultFocused(id) : defaultFocused

    return (
        <Stack
            direction={'row'}
            spacing={2}
            onClick={() => onClick(id)}
            sx={{
                alignItems: 'center',
                maxHeight: 56,
                minHeight: 56,
                userSelect: "none",
                msUserSelect: "none",
                MozUserSelect: "none",
                WebkitUserSelect: "none",
                cursor: 'pointer',
                background: focused && `${theme.palette.primary.contrastText}10`,
                borderRadius: '8px',
                px: 2,
            }}
        >
            {icon}

            <Typography
                variant={'subtitle1'}
                noWrap
                sx={{
                    textTransform: 'capitalize',
                    letterSpacing: .3,
                }}
            >
                {text || id}
            </Typography>
        </Stack>
    )
}

ListItem.propTypes = {
    id: PropTypes.string,
    icon: PropTypes.any,
    text: PropTypes.any,
    onClick: PropTypes.func,
    focused: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

ListItem.defaultProps = {
}

export default ListItem