export const page1 = {
    theme: {
        mode: 'light',
        light: {
            background: '',
            widget: '',
            customColor1: '',
            customColor2: '',
        },
        dark: {
            background: '',
            widget: '',
            customColor1: '',
            customColor2: '',
        },
        'mode3': {
            background: '',
            widget: '',
            customColor1: '',
            customColor2: '',
        },
    },
    scenarios: {
        default: 'kvnaskfjhasdkfja',
        'kvnaskfjhasdkfja': {
            name: '테스트',
            variables: {
                '__url__': 'http://127.0.0.1:9102',
                '__monthly_quantities_equal_rate_original__': [4, 5, 6]
            },
        },
        'dfkajgdf': {
            name: '정식',
            variables: {
                '__url__': ''
            },
        },
    },
    variables: {
        '__url__': { name: '서버 주소', description: '변수 설명', type: 'text', },
        '__transaction_quantity_total_original__': { name: '물량 원본', description: '변수 설명', type: 'text', },
        '__transaction_percentage_original__': { name: '비율 원본', description: '변수 설명', type: 'text', },
        '__monthly_quantities_equal_rate_original__': { name: '균등 비중 (원본)', description: '변수 설명', type: 'array', },
        '__monthly_quantities_model_rate_original__': { name: '모델 비중 (원본)', description: '변수 설명', type: 'array', },
        '__monthly_quantities_equal_vol_original__': { name: '균등 물량 (원본)', description: '변수 설명', type: 'array', },
        '__monthly_quantities_model_vol_original__': { name: '모델 물량 (원본)', description: '변수 설명', type: 'array', },
        '__monthly_quantities_total_rate_original__': { name: '주별 비중 (원본)', description: '변수 설명', type: 'array', },
        '__monthly_quantities_total_vol_original__': { name: '주별 물량 (원본)', description: '변수 설명', type: 'array', },
        '__weights_original__': { name: '가중치 (원본)', description: '변수 설명', type: 'array', },
        '__downloading__': { name: '다운로드 중', description: '변수 설명', type: 'bool', },
        '__saving__': { name: '저장 중', description: '변수 설명', type: 'bool', },
        '__transaction_quantity_total__': { name: '물량', description: '변수 설명', type: 'text', },
        '__transaction_percentage__': { name: '비율', description: '변수 설명', type: 'text', },
        '__has_changes__': { name: '변경사항 유무', description: '변수 설명', type: 'bool', },
        '__weights__': { name: '가중치', description: '변수 설명', type: 'array', },
        '__overall_score__': { name: '전체 점수', description: '변수 설명', type: 'text', },
        '__score_chemvix__': { name: 'chemvix 점수', description: '변수 설명', type: 'text', },
        '__score_daily__': { name: '당주 점수', description: '변수 설명', type: 'text', },
        '__score_trend__': { name: '추이 점수', description: '변수 설명', type: 'text', },
        '__score_date__': { name: '점수 일자', description: '변수 설명', type: 'date', detailedType: 'date', },
        '__workdays_in_each_week__': { name: '각 주 업무 일수', description: '변수 설명', type: 'array', detailedType: 'date' },
        '__workdays__': { name: '업무 날짜', description: '변수 설명', type: 'array', },
        '__chart_ma_price__': { name: '실제 환율 데이터', description: '변수 설명', type: 'array', },
        '__chart_w1__': { name: 'w1 데이터', description: '변수 설명', type: 'array', },
        '__chart_w3__': { name: 'w3 데이터', description: '변수 설명', type: 'array', },
        '__monthly_quantities_model_vol__': { name: '모델 물량', description: '변수 설명', type: 'array', },
        '__monthly_quantities_model_rate__': { name: '모델 비중', description: '변수 설명', type: 'array', },
        '__monthly_quantities_equal_vol__': { name: '균등 물량', description: '변수 설명', type: 'array', },
        '__monthly_quantities_equal_rate__': { name: '균등 비중', description: '변수 설명', type: 'array', },
        '__monthly_quantities_total_vol__': { name: '주별 물량', description: '변수 설명', type: 'array', },
        '__monthly_quantities_total_rate__': { name: '주별 비중', description: '변수 설명', type: 'array', },
        '__w1_predication_date__': { name: 'w1 예측일', description: '변수 설명', type: 'date', detailedType: 'date', },
        '__w3_predication_date__': { name: 'w3 예측일', description: '변수 설명', type: 'date', detailedType: 'date', },
        '__start_date__': { name: '시작일', description: '변수 설명', type: 'date', detailedType: 'date', },
        '__end_date__': { name: '종료일', description: '변수 설명', type: 'date', detailedType: 'date', },
        '__date_range_parts__': { name: 'w1? w3?', description: '변수 설명', type: 'text', },
        '__reading__': { name: '불러오는 중', description: '변수 설명', type: 'bool', },
        '__uploading__': { name: '업로드 중', description: '변수 설명', type: 'bool', },
        '__number_of_weeks__': { name: '주 수', description: '변수 설명', type: 'text', },
        '__is_w1__': { name: 'w1?', description: '변수 설명', type: 'bool', },
        '__upload_file__': { name: '업로드 파일', description: '변수 설명', type: 'file', },
    },
    functions: {
        'vafnj9njiosdvfni': {
            title: '초기 설정',
            functionId: 'fvadsnkl',
            functionInputs: {
                var1: '__workdays_in_each_week__',
                var2: '__transaction_quantity_total__',
                var3: '__transaction_quantity_total_original__',
                var4: '__transaction_percentage__',
                var5: '__transaction_percentage_original__',
                var6: '__weights__',
                var7: '__overall_score__',
                var8: '__score_date__',
                var9: '__score_daily__',
                var10: '__score_trend__',
                var11: '__score_chemvix__',
                var12: '__w1_predication_date__',
                var13: '__w3_predication_date__',
                var14: '__start_date__',
                var15: '__end_date__',
                var16: '__workdays__',
                var17: '__chart_ma_price__',
                var18: '__chart_w1__',
                var19: '__chart_w3__',
                var20: '__monthly_quantities_equal_rate__',
                var21: '__monthly_quantities_equal_rate_original__',
                var22: '__monthly_quantities_model_rate__',
                var23: '__monthly_quantities_model_rate_original__',
                var24: '__monthly_quantities_equal_vol__',
                var25: '__monthly_quantities_equal_vol_original__',
                var26: '__monthly_quantities_model_vol__',
                var27: '__monthly_quantities_model_vol_original__',
                var28: '__monthly_quantities_total_rate__',
                var29: '__monthly_quantities_total_rate_original__',
                var30: '__monthly_quantities_total_vol__',
                var31: '__monthly_quantities_total_vol_original__',
                var32: '__date_range_parts__',
                var33: '__reading__',
                var34: '__url__',
                var35: '__weights_original__',
                var36: '__number_of_weeks__',
                var37: '__is_w1__',
                var38: '__has_changes__',
            }
        },
        'dfhasdfa9sdfh': {
            title: '결과 다운로드',
            functionId: 'lnm3dlfiu9',
            functionInputs: {
                var1: '__downloading__',
                var2: '__url__',
                var3: '__start_date__',
                var4: '__transaction_quantity_total__',
                var5: '__transaction_percentage__',
                var6: '__weights__',
            }
        },
        'fvoinjfodvnjiw': {
            title: '저장',
            functionId: 'skfjbkn',
            functionInputs: {
                var1: '__saving__',
                var2: '__url__',
                var3: '__start_date__',
                var4: '__transaction_quantity_total__',
                var5: '__transaction_percentage__',
                var6: '__weights__',
                var7: '__has_changes__',
                var8: '__m_weights__'
            }
        },
        'kasdjgaksd': {
            title: '업로드',
            functionId: 'udfajsdkf',
            functionInputs: {
                var1: '__uploading__',
                var2: '__url__',
                var3: '__upload_file__',
            }
        },
        'fgndasdfgfsdva': {
            title: '초기화',
            functionId: 'gagasdfasdfsd',
            functionInputs: {
                var1: '__has_changes__',
                var2: '__transaction_quantity_total__',
                var3: '__transaction_quantity_total_original__',
                var4: '__transaction_percentage__',
                var5: '__transaction_percentage_original__',
                var6: '__weights__',
                var7: '__weights_original__',
                var8: '__monthly_quantities_equal_rate__',
                var9: '__monthly_quantities_equal_rate_original__',
                var10: '__monthly_quantities_model_rate__',
                var11: '__monthly_quantities_model_rate_original__',
                var12: '__monthly_quantities_equal_vol__',
                var13: '__monthly_quantities_equal_vol_original__',
                var14: '__monthly_quantities_model_vol__',
                var15: '__monthly_quantities_model_vol_original__',
                var16: '__monthly_quantities_total_rate__',
                var17: '__monthly_quantities_total_rate_original__',
                var18: '__monthly_quantities_total_vol__',
                var19: '__monthly_quantities_total_vol_original__',
            }
        },
        '32frverbsdfmn': {
            title: '수정',
            functionId: 'bvlmkbfmkldnfk',
            functionInputs: {
                var1: '__url__',
                var2: '__start_date__',
                var3: '__end_date__',
                var4: '__date_range_parts__',
                var5: '__weights__',
                var6: '__weights_original__',
                var7: '__number_of_weeks__',
                var8: '__transaction_quantity_total__',
                var9: '__transaction_percentage__',
                var10: '__transaction_quantity_total_original__',
                var11: '__transaction_percentage_original__',
                var13: '__is_w1__',
            }
        },
    },
    events: {
        onStart: 'vafnj9njiosdvfni',
    },
    widgets: {
        '3c457164-609c-4b3b-a3e5-ed01b498356d': {
            categoryId: 'transactionQuantity',
            inputs: {
                title: '거래 물량',
                prefix: '$',
                total: '__transaction_quantity_total__', // 150000
                percentage: '__transaction_percentage__', // 40
                primaryLabel: '균등',
                secondaryLabel: '모델',
                primaryColor: '#d9d9d9',
                secondaryColor: '#ffcdcd',
            },
            loading: '__reading__'
        },
        '801a0ba1-1987-4504-96d7-13d85cb1ee16': {
            categoryId: 'dashboardButtonGroup',
            inputs: {
                resetText: '시뮬레이션 재설정',
                downloadText: '결과 다운로드',
                uploadText: '거래 실적 업로드',
                editText: '수정 모드',
                // confirmText: '시뮬레이션 확정',
                editColor: '#9e0025',
                confirmColor: '#000',
                downloading: '__downloading__',
                uploading: '__uploading__',
                saving: '__saving__',
                hasChanges: '__has_changes__',
                align: 'right',
                selectedFile: '__upload_file__',
            },
            events: {
                uploadClick: 'kasdjgaksd',
                resetClick: 'fgndasdfgfsdva',
                downloadClick: 'dfhasdfa9sdfh',
                editClick: '32frverbsdfmn',
                saveClick: 'fvoinjfodvnjiw',
            },
            loading: '__reading__',
            noShadow: true,
        },
        '6dd18cd1-266d-41a1-a125-5c29149a88be': {
            categoryId: 'dashboardButtonGroup',
            inputs: {
                editText: '수정 모드',
                editColor: '#9e0025',
                confirmColor: '#000',
                downloading: '__downloading__',
                uploading: '__uploading__',
                saving: '__saving__',
                hasChanges: '__has_changes__',
                align: 'right',
                selectedFile: '__upload_file__',
            },
            events: {
                uploadClick: 'kasdjgaksd',
                resetClick: 'fgndasdfgfsdva',
                downloadClick: 'dfhasdfa9sdfh',
                editClick: '32frverbsdfmn',
                saveClick: 'fvoinjfodvnjiw',
            },
            loading: '__reading__',
            noShadow: true,
        },
        'e3def640-773c-4348-aa86-3521099cc6d8': {
            categoryId: 'rankWeight',
            inputs: {
                title: '모델 순위별 가중치',
                values: '__weights__', //[70, 30, 0, 0],
                labels: ['1순위', '2순위', '3순위', '4순위'],
                colors: ['#9e0025', '#9e002599', '#9e00254D', '#9e00251A'],
            },
            loading: '__reading__'
        },
        '86fed474-96eb-4b65-aba0-e55456fb088d': {
            categoryId: 'modelAssessmentIndicator',
            inputs: {
                title: '모델 보조 지표',
                tip: '- 모델 보조지표는 월요일에 업데이트되며, 업데이트 시점의 종합 ChemVDC와 시장 상황과 모델 적용 변수 간 불일치 정도를 표현합니다.' +
                    '\n- 종합 평가 점수가 75점 이상인 경우 모델 사용 자제를 권고합니다.' +
                    '\n  (Red 자제, Green 좋음. Black 데이터없음)' +
                    '\n- Score 데이터가 없을 경우, 일부데이터가 \'-\' 로 보입니다.',
                mainValue: '__overall_score__', // 90
                subTitles: ['ChemVIX', '시장 변동성 불일치 정도'],
                subLabels: ['종합', '당주', '추이'],
                subValues: ['__score_chemvix__', '__score_daily__', '__score_trend__'], // [70, 50, 20],
                dateHint: '반영 일자',
                date: '__score_date__', // '2023-04-21',
            },
            loading: '__reading__'
        },
        '2783c690-d7ed-426e-ae2b-bd27f86ad839': {
            categoryId: 'monthlyAllocationQuantity',
            inputs: {
                title: '월간 배분 물량',
                tip: '- 각 주차별 순위는 (해당주평균-해당월평균) (해당월최댓값-해당월최솟값)의 소숫점 첫자리를 기준으로 정해집니다.' +
                    '\n동일한 순위가 존재할 경우 분배 비율은 두 비율을 합하여 나눈 값으로 할당됩니다.',

                flexes: '__workdays_in_each_week__',
                dates: '__workdays__',

                lineValues: ['__chart_ma_price__', '__chart_w1__', '__chart_w3__'],
                lineColors: ['#007a37', '#9e0025', '#ffcdcd'],
                lineLabels: ['매매기준율', 'W1 예측값', 'W3 예측값'],
                lineYAxis: ['left', 'right', 'right'],
                yAxisLabel: '매매기준율 / 예측값 (원)',
                yAxisLeftLabel: '매매기준율 (원)',
                yAxisRightLabel: '예측값 (원)',
                combineYAxisLabel: '단일 좌표로 합치기',

                barLabels: ['균등', '모델'],
                barValues: ['__monthly_quantities_equal_rate__', '__monthly_quantities_model_rate__'],
                barColors: ['#d9d9d9', '#ffcdcd'],

                tableLabels: ['모델 물량', '모델 비중', '균등 물량', '균등 비중', '주별 물량 (A+B)', '주별 비중 (A+B)'],
                tableValues: [
                    '__monthly_quantities_model_vol__',
                    '__monthly_quantities_model_rate__',
                    '__monthly_quantities_equal_vol__',
                    '__monthly_quantities_equal_rate__',
                    '__monthly_quantities_total_vol__',
                    '__monthly_quantities_total_rate__',
                ],

                w1PredictionDate: '__w1_predication_date__', // '2023.05.05',
                w3PredictionDate: '__w3_predication_date__', // '2022.10.22',
                w1PredictionDateLabel: 'W1 예측 일자',
                w3PredictionDateLabel: 'W3 예측 일자',
            },
            loading: '__reading__'
        },
        '0a900a64-7630-43b7-a28d-4fe13d1f0729': {
            categoryId: 'dateRangeView',
            inputs: {
                color: '#9e0025',
                startDate: '__start_date__', // '2023-12-01',
                endDate: '__end_date__', // '2024-01-01',
                badgeText: '__date_range_parts__', // 'W1',
            },
            events: {
                __onClick: '32frverbsdfmn',
            },
            noShadow: true,
        },
    },
    responsive: [
        {
            cols: 13,
            layout: [
                { id: '0a900a64-7630-43b7-a28d-4fe13d1f0729', x: 0, y: 0 },
                { id: '801a0ba1-1987-4504-96d7-13d85cb1ee16', x: 12, y: 0 },
                { id: '2783c690-d7ed-426e-ae2b-bd27f86ad839', x: 0, y: 1 },
                { id: '86fed474-96eb-4b65-aba0-e55456fb088d', x: 10, y: 1 },
                { id: '3c457164-609c-4b3b-a3e5-ed01b498356d', x: 10, y: 5 },
                { id: 'e3def640-773c-4348-aa86-3521099cc6d8', x: 10, y: 8 },
            ],
            types: {
                '0a900a64-7630-43b7-a28d-4fe13d1f0729': 0,
                '801a0ba1-1987-4504-96d7-13d85cb1ee16': 2,
                '2783c690-d7ed-426e-ae2b-bd27f86ad839': 0,
                '86fed474-96eb-4b65-aba0-e55456fb088d': 0,
                '3c457164-609c-4b3b-a3e5-ed01b498356d': 0,
                'e3def640-773c-4348-aa86-3521099cc6d8': 0,
                '6dd18cd1-266d-41a1-a125-5c29149a88be': 0,
            }
        },
        {
            cols: 12,
            layout: [
                { id: '0a900a64-7630-43b7-a28d-4fe13d1f0729', x: 0, y: 0 },
                { id: '801a0ba1-1987-4504-96d7-13d85cb1ee16', x: 11, y: 0 },
                { id: '2783c690-d7ed-426e-ae2b-bd27f86ad839', x: 0, y: 1 },
                { id: '86fed474-96eb-4b65-aba0-e55456fb088d', x: 9, y: 1 },
                { id: '3c457164-609c-4b3b-a3e5-ed01b498356d', x: 9, y: 5 },
                { id: 'e3def640-773c-4348-aa86-3521099cc6d8', x: 9, y: 8 },
            ],
            types: {
                '0a900a64-7630-43b7-a28d-4fe13d1f0729': 0,
                '801a0ba1-1987-4504-96d7-13d85cb1ee16': 2,
                '2783c690-d7ed-426e-ae2b-bd27f86ad839': 1,
                '86fed474-96eb-4b65-aba0-e55456fb088d': 0,
                '3c457164-609c-4b3b-a3e5-ed01b498356d': 0,
                'e3def640-773c-4348-aa86-3521099cc6d8': 0,
                '6dd18cd1-266d-41a1-a125-5c29149a88be': 0,
            }
        },
        {
            cols: 11,
            layout: [
                { id: '0a900a64-7630-43b7-a28d-4fe13d1f0729', x: 0, y: 0 },
                { id: '801a0ba1-1987-4504-96d7-13d85cb1ee16', x: 10, y: 0 },
                { id: '2783c690-d7ed-426e-ae2b-bd27f86ad839', x: 0, y: 1 },
                { id: '86fed474-96eb-4b65-aba0-e55456fb088d', x: 8, y: 1 },
                { id: '3c457164-609c-4b3b-a3e5-ed01b498356d', x: 8, y: 5 },
                { id: 'e3def640-773c-4348-aa86-3521099cc6d8', x: 8, y: 8 },
            ],
            types: {
                '0a900a64-7630-43b7-a28d-4fe13d1f0729': 0,
                '801a0ba1-1987-4504-96d7-13d85cb1ee16': 2,
                '2783c690-d7ed-426e-ae2b-bd27f86ad839': 2,
                '86fed474-96eb-4b65-aba0-e55456fb088d': 0,
                '3c457164-609c-4b3b-a3e5-ed01b498356d': 0,
                'e3def640-773c-4348-aa86-3521099cc6d8': 0,
                '6dd18cd1-266d-41a1-a125-5c29149a88be': 0,
            }
        },
        {
            cols: 10,
            layout: [
                { id: '0a900a64-7630-43b7-a28d-4fe13d1f0729', x: 0, y: 0 },
                { id: '801a0ba1-1987-4504-96d7-13d85cb1ee16', x: 9, y: 0 },
                { id: '2783c690-d7ed-426e-ae2b-bd27f86ad839', x: 0, y: 1 },
                { id: '86fed474-96eb-4b65-aba0-e55456fb088d', x: 7, y: 1 },
                { id: '3c457164-609c-4b3b-a3e5-ed01b498356d', x: 7, y: 5 },
                { id: 'e3def640-773c-4348-aa86-3521099cc6d8', x: 7, y: 8 },
            ],
            types: {
                '0a900a64-7630-43b7-a28d-4fe13d1f0729': 0,
                '801a0ba1-1987-4504-96d7-13d85cb1ee16': 2,
                '2783c690-d7ed-426e-ae2b-bd27f86ad839': 3,
                '86fed474-96eb-4b65-aba0-e55456fb088d': 0,
                '3c457164-609c-4b3b-a3e5-ed01b498356d': 0,
                'e3def640-773c-4348-aa86-3521099cc6d8': 0,
                '6dd18cd1-266d-41a1-a125-5c29149a88be': 0,
            }
        },
        {
            cols: 9,
            layout: [
                { id: '0a900a64-7630-43b7-a28d-4fe13d1f0729', x: 0, y: 0 },
                { id: '801a0ba1-1987-4504-96d7-13d85cb1ee16', x: 8, y: 0 },
                { id: '2783c690-d7ed-426e-ae2b-bd27f86ad839', x: 0, y: 1 },
                { id: '86fed474-96eb-4b65-aba0-e55456fb088d', x: 0, y: 11 },
                { id: '3c457164-609c-4b3b-a3e5-ed01b498356d', x: 3, y: 11 },
                { id: 'e3def640-773c-4348-aa86-3521099cc6d8', x: 7, y: 11 },
            ],
            types: {
                '0a900a64-7630-43b7-a28d-4fe13d1f0729': 0,
                '801a0ba1-1987-4504-96d7-13d85cb1ee16': 2,
                '2783c690-d7ed-426e-ae2b-bd27f86ad839': 1,
                '86fed474-96eb-4b65-aba0-e55456fb088d': 0,
                '3c457164-609c-4b3b-a3e5-ed01b498356d': 0,
                'e3def640-773c-4348-aa86-3521099cc6d8': 0,
                '6dd18cd1-266d-41a1-a125-5c29149a88be': 0,
            }
        },
        {
            cols: 8,
            layout: [
                { id: '0a900a64-7630-43b7-a28d-4fe13d1f0729', x: 0, y: 0 },
                { id: '6dd18cd1-266d-41a1-a125-5c29149a88be', x: 7, y: 0 },
                { id: '2783c690-d7ed-426e-ae2b-bd27f86ad839', x: 0, y: 1 },
                { id: '86fed474-96eb-4b65-aba0-e55456fb088d', x: 0, y: 11 },
                { id: '3c457164-609c-4b3b-a3e5-ed01b498356d', x: 3, y: 11 },
                { id: 'e3def640-773c-4348-aa86-3521099cc6d8', x: 0, y: 14 },
            ],
            types: {
                '0a900a64-7630-43b7-a28d-4fe13d1f0729': 0,
                '801a0ba1-1987-4504-96d7-13d85cb1ee16': 2,
                '2783c690-d7ed-426e-ae2b-bd27f86ad839': 2,
                '86fed474-96eb-4b65-aba0-e55456fb088d': 0,
                '3c457164-609c-4b3b-a3e5-ed01b498356d': 0,
                'e3def640-773c-4348-aa86-3521099cc6d8': 0,
                '6dd18cd1-266d-41a1-a125-5c29149a88be': 0,
            }
        },
        {
            cols: 7,
            layout: [
                { id: '0a900a64-7630-43b7-a28d-4fe13d1f0729', x: 0, y: 0 },
                { id: '6dd18cd1-266d-41a1-a125-5c29149a88be', x: 6, y: 0 },
                { id: '2783c690-d7ed-426e-ae2b-bd27f86ad839', x: 0, y: 1 },
                { id: '86fed474-96eb-4b65-aba0-e55456fb088d', x: 0, y: 10 },
                { id: '3c457164-609c-4b3b-a3e5-ed01b498356d', x: 3, y: 10 },
                { id: 'e3def640-773c-4348-aa86-3521099cc6d8', x: 0, y: 13 },
            ],
            types: {
                '0a900a64-7630-43b7-a28d-4fe13d1f0729': 0,
                '801a0ba1-1987-4504-96d7-13d85cb1ee16': 2,
                '2783c690-d7ed-426e-ae2b-bd27f86ad839': 3,
                '86fed474-96eb-4b65-aba0-e55456fb088d': 0,
                '3c457164-609c-4b3b-a3e5-ed01b498356d': 0,
                'e3def640-773c-4348-aa86-3521099cc6d8': 0,
                '6dd18cd1-266d-41a1-a125-5c29149a88be': 0,
            }
        },
    ],
}