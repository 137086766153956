import {Button} from "@mui/material";

function TextButton(props) {
    return (
        <Button
            variant={'contained'}
            color={'secondary'}
            disableFocusRipple
            disableTouchRipple
            {...props}
            sx={{
                textTransform: 'capitalize',
                height: !props.height && '48px',
                borderRadius: '12px',
                boxSizing: 'border-box',
                ...props.sx
            }}
        >
            {props.children}
        </Button>
    )
}

export default TextButton