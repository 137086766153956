export const functions = {

    'fvadsnkl': {
        title: 'Get all',
        execute: (
            {
                var1, var2, var3, var4, var5, var6, var7, var8, var9, var10, var11, var12, var13, var14, var15, var16,
                var17, var18, var19, var20, var21, var22, var23, var24, var25, var26, var27, var28, var29, var30, var31,
                var32, var33, var34, var35, var36, var37, var38,
            }
        ) => [
            { type: 'block', id: 'cvv', inputs: { key: var33, value: true, immediately: true } },
            { type: 'block', id: 'gd', inputs: { date: var14, format: 'YYYY-MM-DD', output: var14 } },
            {
                type: 'api',
                inputs: {
                    url: var34,
                    params: { target_date: var14 },
                },
                outputs: {
                    'data.workday.numberOfWorkDayEachWeek': var1,
                    'data.simulate.quantity': var3,
                    'data.simulate.equal_percentage': var5,
                    'data.simulate.rank_weights': var35,

                    'data.score.total_score': var7,
                    'data.score.date': var8,
                    'data.score.market_daily': var9,
                    'data.score.market_trend': var10,
                    'data.score.chemvix': var11,

                    'data.chart.w1_pred_date': var12,
                    'data.chart.w3_pred_date': var13,
                    'data.start_date': var14,
                    'data.end_date': var15,

                    'data.workday.dateRangeEachWeek': var16,

                    'data.chart.ma_price': var17,
                    'data.chart.w1': var18,
                    'data.chart.w3': var19,

                    'data.simulate_result.equal_rate': var21,
                    'data.simulate_result.model_rate': var23,

                    'data.simulate_result.equal_vol': var25,
                    'data.simulate_result.model_vol': var27,
                    'data.simulate_result.total_rate': var29,
                    'data.simulate_result.total_vol': var31,

                    'data.workday.isW1': var37,
                },
                success: { type: 'block', id: 'cvv', inputs: { key: var33, value: false, immediately: true } },
                defaultError: {
                    type: 'block',
                    id: 'sb',
                    inputs: {
                        message: '불러오기를 실패하였습니다. 나중에 다시 시도하세요',
                        autoHideDuration: 1500,
                        persist: true,
                        button: 'reload',
                    }
                },
                errors: {
                    // '__ERR_NETWORK': { type: 'block', id: 'sb', inputs: { message: '서버에 문제 발생' } },
                    // 'TARGET_DATE_REQUIRED': { type: 'block', id: 'sb', inputs: { message: 'ERROR: TARGET_DATE_REQUIRED' } },
                }
            },
            { type: 'function', id: 'jsfdgsdfgsdfg', inputs: { var1: var32, var2: var36, var3: var35, var5: var37, } },
            { type: 'block', id: 'farr', inputs: { array: var16, flatKey: 'dates', output: var16 } },
            { type: 'block', id: 'farr', inputs: { array: var17, flatKey: 'value', output: var17 } },
            { type: 'block', id: 'farr', inputs: { array: var18, flatKey: 'value', output: var18 } },
            { type: 'block', id: 'farr', inputs: { array: var19, flatKey: 'value', output: var19 } },
            {
                type: 'function',
                id: 'jkghfgsfg',
                inputs: { var1: var38, var2: var2, var3: var3, var4: var4, var5: var5, var6: var6, var7: var35 }
            },
            {
                type: 'case',
                condition: `${var38}`,
                t: {
                    type: 'api',
                    inputs: {
                        url: `${var34}/simulation-result`,
                        params: { target_date: var14, quantity: var2, equal_percentage: var4, rank_weights: var6, },
                    },
                    outputs: {
                        'data.equal_rate': var20,
                        'data.model_rate': var22,
                        'data.equal_vol': var24,
                        'data.model_vol': var26,
                        'data.total_rate': var28,
                        'data.total_vol': var30,
                    },
                    // success: {},
                    // defaultError: {},
                    errors: {
                        '__ERR_NETWORK': { type: 'block', id: 'sb', inputs: { message: '서버에 문제 발생' } },
                        // 'TARGET_DATE_REQUIRED': { type: 'block', id: 'sb', inputs: { message: 'ERROR: TARGET_DATE_REQUIRED' } },
                    }
                },
                f: {
                    type: 'function',
                    id: 'gagasdfasdfsd',
                    inputs: {
                        var2: var2,
                        var3: var3,
                        var4: var4,
                        var5: var5,
                        var6: var20,
                        var7: var21,
                        var8: var22,
                        var9: var23,
                        var10: var24,
                        var11: var25,
                        var12: var26,
                        var13: var27,
                        var14: var28,
                        var15: var29,
                        var16: var30,
                        var17: var31,
                        var18: var6,
                        var19: var35,
                    }
                }
            },
        ],
    },

    'udfajsdkf': {
        title: 'Upload File',
        execute: ({ var1, var2, var3, var4, var5, var6, var7, }) => [
            { type: 'block', id: 'cvv', inputs: { key: var1, value: true, immediately: true } },
            {
                type: 'api',
                inputs: {
                    url: `${var2}/transaction`,
                    method: 'post',
                    body: {
                        type: 'form',
                        file: var3
                    }
                },
                success: { type: 'block', id: 'sb', inputs: { message: '거래 실적 업로드 성공', } },
                defaultError: { type: 'block', id: 'sb', inputs: { message: '거래 실적 업로드 실패', variant: 'error' } },
                errors: {
                    '__ERR_NETWORK': { type: 'block', id: 'sb', inputs: { message: '서버에 문제 발생', } },
                }
            },
            { type: 'block', id: 'cvv', inputs: { key: var1, value: false, immediately: true } },
        ],
    },

    'skfjbkn': {
        title: 'Save',
        execute: ({ var1, var2, var3, var4, var5, var6, var7, var8 }) => [
            { type: 'block', id: 'cvv', inputs: { key: var1, value: true, immediately: true } },
            { type: 'block', id: 'ats', inputs: { array: var6, output: var8 } }, // python 서버 오류 때문
            {
                type: 'api',
                inputs: {
                    url: `${var2}/simulation`,
                    method: 'post',
                    body: { target_date: var3, quantity: var4, equal_percentage: var5, rank_weights: var8 },
                },
                success: { type: 'block', id: 'cvv', inputs: { key: var7, value: false } },
                defaultError: { type: 'block', id: 'sb', inputs: { message: '데이터베이스에 확정 실패', variant: 'error' } },
                errors: {
                    '__ERR_NETWORK': { type: 'block', id: 'sb', inputs: { message: '서버에 문제 발생', } },
                    // 'TARGET_DATE_REQUIRED': { type: 'block', id: 'sb', inputs: { message: 'ERROR: TARGET_DATE_REQUIRED' } },
                }
            },
            { type: 'block', id: 'cvv', inputs: { key: var1, value: false, immediately: true } },
        ],
    },

    'lnm3dlfiu9': {
        title: 'Download',
        execute: ({ var1, var2, var3, var4, var5, var6, }) => [
            { type: 'block', id: 'cvv', inputs: { key: var1, value: true, immediately: true } },
            {
                type: 'api',
                inputs: {
                    url: var2,
                    params: { target_date: var3,  quantity: var4, equal_percentage: var5, rank_weights: var6 },
                    responseType: 'blob',
                },
                outputs: {
                    fileType: 'text/csv',
                    headerFileName: 'custom-filename',
                    fileName: 'simulation_report.csv',
                },
                success: { type: 'block', id: 'sb', inputs: { message: '시뮬레이션 결과 다운로드 성공' } },
                defaultError: { type: 'block', id: 'sb', inputs: { message: '시뮬레이션 결과 다운로드 실패', variant: 'error' } },
                errors: {
                    '__ERR_NETWORK': { type: 'block', id: 'sb', inputs: { message: '서버에 문제 발생', } },
                    // 'TARGET_DATE_REQUIRED': { type: 'block', id: 'sb', inputs: { message: 'ERROR: TARGET_DATE_REQUIRED' } },
                }
            },
            { type: 'block', id: 'cvv', inputs: { key: var1, value: false, immediately: true } },
        ],
    },

    'jkghfgsfg': {
        title: 'Check Changes',
        execute: ({ var1, var2, var3, var4, var5, var6, var7, }) => [
            {
                type: 'case',
                condition: `(${var2} ? ${var2} !== ${var3} : false) || (${var4} ? ${var4} !== ${var5} : false) || (Boolean(${var6}) ? JSON.stringify(${var6}) !== JSON.stringify(${var7}) : false)`,
                t: { type: 'block', id: 'cvv', inputs: { key: var1, value: true } },
                f: { type: 'block', id: 'cvv', inputs: { key: var1, value: false } },
            },
        ],
    },

    'gagasdfasdfsd': {
        title: 'Reset Changes',
        execute: (
            {
                var1, var2, var3, var4, var5, var6, var7, var8, var9, var10, var11, var12, var13, var14, var15, var16,
                var17, var18, var19,
            }
        ) => [
            { type: 'block', id: 'cvv', inputs: { key: var1, value: false } },
            { type: 'block', id: 'cvv', inputs: { key: var2, value: var3 } },
            { type: 'block', id: 'cvv', inputs: { key: var4, value: var5 } },
            { type: 'block', id: 'cvv', inputs: { key: var6, value: var7 } },
            { type: 'block', id: 'cvv', inputs: { key: var8, value: var9 } },
            { type: 'block', id: 'cvv', inputs: { key: var10, value: var11 } },
            { type: 'block', id: 'cvv', inputs: { key: var12, value: var13 } },
            { type: 'block', id: 'cvv', inputs: { key: var14, value: var15 } },
            { type: 'block', id: 'cvv', inputs: { key: var16, value: var17 } },
            { type: 'block', id: 'cvv', inputs: { key: var18, value: var19 } },
        ],
    },

    'haSDFGSDAF': {
        title: 'on date range is changed',
        execute: ({ var1, var2, var3, var4, var5, var6, var7, var8, var9, var10, var11, var12, var13, var14, }) => [
            { type: 'block', id: 'cvv', inputs: { key: var1, value: true, immediately: true } },
            {
                type: 'api',
                inputs: {
                    url: `${var2}/workdays`,
                    params: { date: var3 },
                },
                outputs: {
                    'data.start_date': var3,
                    'data.end_date': var13,
                    'data.isW1': var12,
                },
                // success: {},
                defaultError: { type: 'block', id: 'sb', inputs: { message: '데이터를 가져오는데 실패했습니다', variant: 'error' } },
                errors: {
                    '__ERR_NETWORK': { type: 'block', id: 'sb', inputs: { message: '서버에 문제 발생', } },
                    // 'TARGET_DATE_REQUIRED': { type: 'block', id: 'sb', inputs: { message: 'ERROR: TARGET_DATE_REQUIRED' } },
                }
            },
            {
                type: 'api',
                inputs: {
                    url: `${var2}/simulation`,
                    params: { target_date: var3 },
                },
                outputs: {
                    'data.quantity': [var4, var5],
                    'data.equal_percentage': [var6, var7],
                    'data.rank_weights': var8,
                },
                // success: {},
                defaultError: { type: 'block', id: 'sb', inputs: { message: '시뮬레이션 데이터를 가져오는데 실패했습니다', variant: 'error' } },
                errors: {
                    '__ERR_NETWORK': { type: 'block', id: 'sb', inputs: { message: '서버에 문제 발생', } },
                    // 'TARGET_DATE_REQUIRED': { type: 'block', id: 'sb', inputs: { message: 'ERROR: TARGET_DATE_REQUIRED' } },
                }
            },
            { type: 'function', id: 'jsfdgsdfgsdfg', inputs: { var1: var10, var2: var11, var3: var8, var4: var9, var5: var12 } },
            { type: 'block', id: 'cvv', inputs: { key: var14, value: false } },
            { type: 'block', id: 'cvv', inputs: { key: var1, value: false, immediately: true } },
        ],
    },

    'bvlmkbfmkldnfk': {
        title: 'Overlay edit page',
        execute: ({ var1, var2, var3, var4, var5, var6, var7, var8, var9, var10, var11, var13, }) => [
            {
                type: 'block',
                id: 'op',
                inputs: {
                    pageId: 'edit',
                    visibleData: {
                        '__url__': var1,
                    },
                    passData: {
                        '__start_date__': var2,
                        '__end_date__': var3,
                        '__date_range_parts__': var4,
                        '__weights__': var5,
                        '__weights_original__': var6,
                        '__number_of_weeks__': var7,
                        '__transaction_quantity_total__': var8,
                        '__transaction_percentage__': var9,
                        '__transaction_quantity_total_original__': var10,
                        '__transaction_percentage_original__': var11,
                        '__is_w1__': var13,
                    },
                    scroll: 'body',
                    style: {
                        background: {
                            background: 'rgba(255,255,255,0.98)',
                        },
                        content: {
                            background: 'transparent',
                            height: '100%',
                        }
                    },
                }
            },
        ],
    },

    'jsfdgsdfgsdfg': {
        title: 'Parse simulate data',
        execute: ({ var1, var2, var3, var4, var5, }) => [
            { type: 'block', id: 'stna', inputs: { string: var3, output: [var3, var4] } },
            {
                type: 'case',
                condition: `${var5}`,
                t: { type: 'block', id: 'cvv', inputs: { key: var1, value: 'W1' } },
                f: { type: 'block', id: 'cvv', inputs: { key: var1, value: 'W3' } },
            },
            {
                type: 'case',
                condition: `${var1} === 'W1'`,
                t: { type: 'block', id: 'cvv', inputs: { key: var2, value: 4 } },
                f: { type: 'block', id: 'cvv', inputs: { key: var2, value: 2 } },
            },
        ],
    },

    'vsdfasdfasdf': {
        title: 'Move to main page',
        execute: ({ var1, var2, var3, var4, var5, }) => [
            {
                type: 'block',
                id: 'mtp',
                inputs: {
                    pageIdOrUrl: 'main',
                    visibleData: {
                        '__url__': var1,
                        '__start_date__': var2,
                    },
                    passData: {
                        '__transaction_quantity_total__': var3,
                        '__transaction_percentage__': var4,
                        '__weights__': var5,
                    },
                    forceRefresh: true,
                }
            },
        ],
    },

    'vsdfasdfafgfgsdf': {
        title: 'Move to main page - cancel',
        execute: ({ var1 }) => [
            {
                type: 'block',
                id: 'mtp',
                inputs: {
                    pageIdOrUrl: 'main',
                    visibleData: {
                        '__url__': var1,
                    },
                }
            },
        ],
    },

}