import dayjs from "dayjs";
import {Button} from "@mui/material";

export const blocks = ({__getValue, __changeValue, __move, __overlay, __snackbar}) => ({

    a: {
        name: 'Alert',
        execute: ({ title }) => {
            console.log(title, __getValue(title))
            // alert(__getValue(title))
        },
    },

    sb: {
        name: 'Build Snackbar',
        execute: ({ message, variant, preventDuplicate, autoHideDuration = 3000, persist, button }) => {

            let _buttonComponent

            switch (button) {
                case 'reload':
                    _buttonComponent = (
                        <Button onClick={() => window.location.reload()}>
                            Reload
                        </Button>
                    )
                    break
            }

            __snackbar(message, { variant, preventDuplicate, autoHideDuration, persist, action: () => _buttonComponent })

            // enqueueSnackbar(incorrect_weights, {autoHideDuration: 1500})
            // enqueueSnackbar(incorrect_quantity, {autoHideDuration: 1500})
            // enqueueSnackbar(incorrect_equal, {autoHideDuration: 1500})
        },
    },

    cvv: {
        name: 'Change Variable Value',
        execute: ({key, value, immediately}) => {
            __changeValue(key, __getValue(value), immediately)
        }
    },

    cpb: {
        name: 'Change Page Background',
        execute: ({color}) => {
            document.body.style.background = __getValue(color)
        }
    },

    cpc: {
        name: 'Change Page Color',
        execute: ({color}) => {
            document.body.style.color = __getValue(color)
        }
    },

    gd: {
        name: 'Get Today',
        execute: ({ date, format, output }) => {
            const formattedDate = dayjs(__getValue(date)).format(format)
            __changeValue(output, formattedDate)
        }
    },

    ca: {
        name: 'Combine arrays',
        execute: ({ input, output }) => {
            // __changeValue(newKey, data.concat(array2))
        }
    },

    mtp: {
        name: 'Move to page',
        execute: ({ pageIdOrUrl, visibleData, passData, forceNew, forceRefresh }) => {

            let convertedVisibleData = {}
            let convertedData = {}

            if (visibleData) {
                for (let key in visibleData) {
                    const _value = __getValue(visibleData[key])
                    if (!!_value) convertedVisibleData[key] = __getValue(visibleData[key])
                }
            }

            if (passData) {
                for (let key in passData) {
                    const _value = __getValue(passData[key])
                    if (!!_value) convertedData[key] = __getValue(passData[key])
                }
            }

            __move(pageIdOrUrl, convertedVisibleData, convertedData, forceNew, forceRefresh)
        }
    },

    op: {
        name: 'overlay page',
        execute: ({ pageId, visibleData, passData, style, transition, scroll }) => {

            let convertedVisibleData = {}
            let convertedData = {}

            if (visibleData) {
                for (let key in visibleData) {
                    const _value = __getValue(visibleData[key])
                    if (!!_value) convertedVisibleData[key] = __getValue(visibleData[key])
                }
            }

            if (passData) {
                for (let key in passData) {
                    const _value = __getValue(passData[key])
                    if (!!_value) convertedData[key] = __getValue(passData[key])
                }
            }

            __overlay(pageId, convertedVisibleData, convertedData, style, transition, scroll)
        }
    },

    stna: {
        name: 'String to Number Array',
        execute: ({ string, split = ',', output }) => {
            const inputString = __getValue(string)

            if (typeof inputString === 'string') {
                const arr = inputString?.split(split).map(item => Number(item.trim()))
                __changeValue(output, arr)
            }
        }
    },

    ats: {
        name: 'Array to string',
        execute: ({ array, join = ',', output }) => {
            const inputArray = __getValue(array)
            __changeValue(output, inputArray?.join(join))
        }
    },

    farr: {
        name: 'Flatten array',
        execute: ({ array, flatKey, output }) => {
            const inputArray = __getValue(array)

            if (Array.isArray(inputArray)) {
                const arr = inputArray.flatMap(item => {
                    const keys = flatKey.split('.')
                    let nestedValue = item;

                    for (const key of keys) {
                        nestedValue = nestedValue[key]
                    }

                    return nestedValue
                })

                __changeValue(output, arr)
            }
        }
    },

    // replacePage: (url) => {
    //
    // },
    //
    // goBackPage: () => {
    //
    // },
    //
    // callApi: async (method, url, parameter, body) => {
    //
    // }

})