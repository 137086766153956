import React, {useState} from "react";
import PropTypes from "prop-types";
import {strings} from "../../values/strings";
import SidebarContentContainer from "../../containers/SidebarContentContainer";
import {useTheme} from "@mui/material";

function WidgetProperty(props) {

    const {
        toolbar
    } = props

    const theme = useTheme()

    return (
        <SidebarContentContainer
            key={'sidebar'}
            defaultIndex={0}
            toolbar={toolbar}
            tabs={[
                {
                    title: strings.property_short, // size
                    // layout: (
                    //
                    // )
                },
                {
                    title: strings.color,  // widgetColor
                    // layout: (
                    //      <EventPropertiesSidebar
                    //          id={componentId}
                    //          title={'Component Events'}
                    //          events={parent.events[componentId]}
                    //          sourceId={data.sourceId}
                    //          onAdd={onAddComponentEvent}
                    //          onEdit={onEditComponentEvent}
                    //          onDelete={onRemoveComponentEvent}
                    //      />
                    // ),
                },
                {
                    title: strings.input, // loading
                },
            ]}
        />
    )
}

WidgetProperty.propTypes = {
    toolbar: PropTypes.any,
}

WidgetProperty.defaultProps = {
}

export default WidgetProperty