import {Stack, useTheme} from "@mui/material";
import TextArea from "../TextArea";
import TextButton from "../TextButton";
import React from "react";

function SearchBox(
    {
        placeholder,
        value,
        onChange,
        buttonText,
        onClick,
        textAreaProps,
        buttonProps,
        ...props
    }
) {
    const theme = useTheme();
    return (
        <Stack
            direction={'column'}
            spacing={1.5}
            p={2}
            {...props}
            sx={{
                position: 'relative',
                background: theme.palette.primary.main,
                borderRadius: '16px',
                boxShadow: "0px 0px 24px #00000020",
                maxWidth: 1000,
                ...props.sx
            }}
        >
            <TextArea
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                {...textAreaProps}
                style={{
                    maxHeight: 250,
                    border: `none`,
                    backgroundColor: theme.palette.primary.light,
                    ...textAreaProps?.style
                }}
                inputProps={{
                    ...textAreaProps?.inputProps,
                    style: {
                        minHeight: 100,
                        ...textAreaProps?.inputProps?.style,
                    },
                }}
            />

            {buttonText &&
                <TextButton onClick={onClick} {...buttonProps}>
                    {buttonText}
                </TextButton>
            }
        </Stack>
    )
}

export default SearchBox