import {EN} from "./la-en/strings";
import {KR} from "./la-kr/strings";
import {EN_PLURALS} from "./la-en/plurals";
import {KR_PLURALS} from "./la-kr/plurals";

export const languages = [
    {
        key: "ko",
        alt: ["kr", "kor", "ko", "ko-kp", "ko-kr"],
        name: "한국어",
        icon: "https://cdn-icons-png.flaticon.com/512/197/197582.png",
        strings: KR,
        plurals: KR_PLURALS,
    },
    {
        key: "en",
        alt: ["en", "eng", "en-us", "en-au", "en-bz", "en-ca", "en-ie", "en-jm", "en-nz", "en-ph", "en-za", "en-tt", "en-gb", "en-us", "en-zw"],
        name: "English",
        icon: "https://cdn-icons-png.flaticon.com/512/197/197484.png",
        strings: EN,
        plurals: EN_PLURALS,
    },
];

export const detectLanguage = () => {
    const languageAlt = localStorage.getItem("la") || window.navigator.userLanguage || window.navigator.language;

    const searchLanguage = languages.find(e => e.alt.find(alt => alt === languageAlt?.toLowerCase()));
    if (!searchLanguage) return languages[0];
    return searchLanguage;
}

export const strings = {...languages[0].strings, ...detectLanguage().strings};
export const plurals = detectLanguage().plurals;



/*
<resources>
    <plurals name="test0">
        <item quantity="one">Test ok</item>
        <item quantity="other">Tests ok</item>
    </plurals>
    <plurals name="test1">
        <item quantity="one">%d test ok</item>
        <item quantity="other">%d tests ok</item>
    </plurals>
    <plurals name="test2">
        <item quantity="one">%2$s: %1$d test ok</item>
        <item quantity="other">%2$s: %1$d tests ok</item>
    </plurals>
    <plurals name="test3">
        <item quantity="one">%3$s: %1$d test out of %2$d ok</item>
        <item quantity="other">%3$s: %1$d tests out of %2$d ok</item>
    </plurals>
</resources>
*/

// getResources().getQuantityString(R.plurals.test3, success, success, total, group)


/*
        "af":    "Afrikaans",
        "sq":    "Albanian",
        "an":    "Aragonese",
        "ar":    "Arabic (Standard)",
        "ar-dz": "Arabic (Algeria)",
        "ar-bh": "Arabic (Bahrain)",
        "ar-eg": "Arabic (Egypt)",
        "ar-iq": "Arabic (Iraq)",
        "ar-jo": "Arabic (Jordan)",
        "ar-kw": "Arabic (Kuwait)",
        "ar-lb": "Arabic (Lebanon)",
        "ar-ly": "Arabic (Libya)",
        "ar-ma": "Arabic (Morocco)",
        "ar-om": "Arabic (Oman)",
        "ar-qa": "Arabic (Qatar)",
        "ar-sa": "Arabic (Saudi Arabia)",
        "ar-sy": "Arabic (Syria)",
        "ar-tn": "Arabic (Tunisia)",
        "ar-ae": "Arabic (U.A.E.)",
        "ar-ye": "Arabic (Yemen)",
        "hy":    "Armenian",
        "as":    "Assamese",
        "ast":   "Asturian",
        "az":    "Azerbaijani",
        "eu":    "Basque",
        "bg":    "Bulgarian",
        "be":    "Belarusian",
        "bn":    "Bengali",
        "bs":    "Bosnian",
        "br":    "Breton",
        "bg":    "Bulgarian",
        "my":    "Burmese",
        "ca":    "Catalan",
        "ch":    "Chamorro",
        "ce":    "Chechen",
        "zh":    "Chinese",
        "zh-hk": "Chinese (Hong Kong)",
        "zh-cn": "Chinese (PRC)",
        "zh-sg": "Chinese (Singapore)",
        "zh-tw": "Chinese (Taiwan)",
        "cv":    "Chuvash",
        "co":    "Corsican",
        "cr":    "Cree",
        "hr":    "Croatian",
        "cs":    "Czech",
        "da":    "Danish",
        "nl":    "Dutch (Standard)",
        "nl-be": "Dutch (Belgian)",
"en":    "English",
"en-au": "English (Australia)",
"en-bz": "English (Belize)",
"en-ca": "English (Canada)",
"en-ie": "English (Ireland)",
"en-jm": "English (Jamaica)",
"en-nz": "English (New Zealand)",
"en-ph": "English (Philippines)",
"en-za": "English (South Africa)",
"en-tt": "English (Trinidad & Tobago)",
"en-gb": "English (United Kingdom)",
"en-us": "English (United States)",
"en-zw": "English (Zimbabwe)",
        "eo":    "Esperanto",
        "et":    "Estonian",
        "fo":    "Faeroese",
        "fa":    "Farsi",
        "fj":    "Fijian",
        "fi":    "Finnish",
        "fr":    "French (Standard)",
        "fr-be": "French (Belgium)",
        "fr-ca": "French (Canada)",
        "fr-fr": "French (France)",
        "fr-lu": "French (Luxembourg)",
        "fr-mc": "French (Monaco)",
        "fr-ch": "French (Switzerland)",
        "fy":    "Frisian",
        "fur":   "Friulian",
        "gd":    "Gaelic (Scots)",
        "gd-ie": "Gaelic (Irish)",
        "gl":    "Galacian",
        "ka":    "Georgian",
        "de":    "German (Standard)",
        "de-at": "German (Austria)",
        "de-de": "German (Germany)",
        "de-li": "German (Liechtenstein)",
        "de-lu": "German (Luxembourg)",
        "de-ch": "German (Switzerland)",
        "el":    "Greek",
        "gu":    "Gujurati",
        "ht":    "Haitian",
        "he":    "Hebrew",
        "hi":    "Hindi",
        "hu":    "Hungarian",
        "is":    "Icelandic",
        "id":    "Indonesian",
        "iu":    "Inuktitut",
        "ga":    "Irish",
        "it":    "Italian (Standard)",
        "it-ch": "Italian (Switzerland)",
        "ja":    "Japanese",
        "kn":    "Kannada",
        "ks":    "Kashmiri",
        "kk":    "Kazakh",
        "km":    "Khmer",
        "ky":    "Kirghiz",
        "tlh":   "Klingon",
"ko":    "Korean",
"ko-kp": "Korean (North Korea)",
"ko-kr": "Korean (South Korea)",
        "la":    "Latin",
        "lv":    "Latvian",
        "lt":    "Lithuanian",
        "lb":    "Luxembourgish",
        "mk":    "FYRO Macedonian",
        "ms":    "Malay",
        "ml":    "Malayalam",
        "mt":    "Maltese",
        "mi":    "Maori",
        "mr":    "Marathi",
        "mo":    "Moldavian",
        "nv":    "Navajo",
        "ng":    "Ndonga",
        "ne":    "Nepali",
        "no":    "Norwegian",
        "nb":    "Norwegian (Bokmal)",
        "nn":    "Norwegian (Nynorsk)",
        "oc":    "Occitan",
        "or":    "Oriya",
        "om":    "Oromo",
        "fa":    "Persian",
        "fa-ir": "Persian/Iran",
        "pl":    "Polish",
        "pt":    "Portuguese",
        "pt-br": "Portuguese (Brazil)",
        "pa":    "Punjabi",
        "pa-in": "Punjabi (India)",
        "pa-pk": "Punjabi (Pakistan)",
        "qu":    "Quechua",
        "rm":    "Rhaeto-Romanic",
        "ro":    "Romanian",
        "ro-mo": "Romanian (Moldavia)",
        "ru":    "Russian",
        "ru-mo": "Russian (Moldavia)",
        "sz":    "Sami (Lappish)",
        "sg":    "Sango",
        "sa":    "Sanskrit",
        "sc":    "Sardinian",
        "gd":    "Scots Gaelic",
        "sd":    "Sindhi",
        "si":    "Singhalese",
        "sr":    "Serbian",
        "sk":    "Slovak",
        "sl":    "Slovenian",
        "so":    "Somani",
        "sb":    "Sorbian",
        "es":    "Spanish",
        "es-ar": "Spanish (Argentina)",
        "es-bo": "Spanish (Bolivia)",
        "es-cl": "Spanish (Chile)",
        "es-co": "Spanish (Colombia)",
        "es-cr": "Spanish (Costa Rica)",
        "es-do": "Spanish (Dominican Republic)",
        "es-ec": "Spanish (Ecuador)",
        "es-sv": "Spanish (El Salvador)",
        "es-gt": "Spanish (Guatemala)",
        "es-hn": "Spanish (Honduras)",
        "es-mx": "Spanish (Mexico)",
        "es-ni": "Spanish (Nicaragua)",
        "es-pa": "Spanish (Panama)",
        "es-py": "Spanish (Paraguay)",
        "es-pe": "Spanish (Peru)",
        "es-pr": "Spanish (Puerto Rico)",
        "es-es": "Spanish (Spain)",
        "es-uy": "Spanish (Uruguay)",
        "es-ve": "Spanish (Venezuela)",
        "sx":    "Sutu",
        "sw":    "Swahili",
        "sv":    "Swedish",
        "sv-fi": "Swedish (Finland)",
        "sv-sv": "Swedish (Sweden)",
        "ta":    "Tamil",
        "tt":    "Tatar",
        "te":    "Teluga",
        "th":    "Thai",
        "tig":   "Tigre",
        "ts":    "Tsonga",
        "tn":    "Tswana",
        "tr":    "Turkish",
        "tk":    "Turkmen",
        "uk":    "Ukrainian",
        "hsb":   "Upper Sorbian",
        "ur":    "Urdu",
        "ve":    "Venda",
        "vi":    "Vietnamese",
        "vo":    "Volapuk",
        "wa":    "Walloon",
        "cy":    "Welsh",
        "xh":    "Xhosa",
        "ji":    "Yiddish",
        "zu":    "Zulu",
 */