import {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {Box} from "@mui/material";

const ScrollableContainer = (props) => {

    const {
        hideScrollbar,
        children,
        topContent,
        stickyContent,
        scrollProps = {},
        sx,
        ...rest
    } = props

    const stickyRef = useRef()
    const topRef = useRef()
    const scrollRef = useRef()

    const [stickyHeight, setStickyHeight] = useState(0)
    const [topHeight, setTopHeight] = useState(0)
    const [sticked, setSticked] = useState(false)

    useEffect(() => {
        setStickyHeight(stickyRef.current?.clientHeight)
    }, [stickyRef.current])

    useEffect(() => {
        setTopHeight(topRef.current?.clientHeight)
    }, [topRef.current])

    const handleScroll = (e) => {
        const _scrollTop = e.target.scrollTop
        const _offset = topHeight - _scrollTop
        const _stickyPosition = _offset < 0 ? 0 : _offset
        setSticked(_stickyPosition <= 0)
    }

    useEffect(() => {
        if (sticked) {
            scrollRef.current?.scrollTo({top: topHeight})
        }
    }, [sticked])

    return (
        <Box
            {...rest}
            sx={{
                position: 'relative',
                paddingTop: `${sticked ? stickyHeight : 0}px`,
                boxSizing: 'border-box',
                ...sx
            }}
        >
            {stickyContent &&
                <Box
                    ref={stickyRef}
                    sx={{
                        position: 'absolute',
                        top: sticked ? 0 : -1000,
                        right: 0,
                        left: 0,
                    }}
                >
                    {stickyContent}
                </Box>
            }

            <Box
                ref={scrollRef}
                onScroll={handleScroll}
                sx={{
                    height: `100%`,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    boxSizing: 'border-box',
                    position: 'relative',
                    '&::-webkit-scrollbar': hideScrollbar ? { width: 0 } : {},
                    ...scrollProps,
                }}
            >
                {topContent && <Box ref={topRef}>{topContent}</Box>}
                {stickyContent && !sticked && <Box>{stickyContent}</Box>}
                {children}
            </Box>
        </Box>
    )
}

ScrollableContainer.propTypes = {
    children: PropTypes.any,
    hideScrollbar: PropTypes.bool,
    stickyContent: PropTypes.any,
    topContent: PropTypes.any,
    scrollProps: PropTypes.object,
}

ScrollableContainer.defaultProps = {
    hideScrollbar: false
}

export default ScrollableContainer