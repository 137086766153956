import {Box, Stack, useTheme} from "@mui/material";
import Logo from "../../assets/Logo";
import dimens from "../../values/dimens";

function MainToolBar(props) {
    const {children} = props;
    const theme = useTheme()

    return (
        <Stack
            direction={'row'}
            spacing={2}
            position={'fixed'}
            top={0}
            right={0}
            left={0}
            minHeight={dimens.toolbar.height}
            alignItems={'center'}
            px={4}
            py={2}
            boxSizing={'border-box'}
            sx={{
                pointerEvents: 'none',
            }}
        >
            <Logo />
            <Box flexGrow={1} />
            {children}
        </Stack>
    )
}

export default MainToolBar