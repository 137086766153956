// import {pluralSwitch} from "../../res/lang/plurals";

const plurals = {
    // memberCount: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d member",
    //         one: "%d member",
    //         other: "%d members",
    //     }, num, d1);
    // },
    // noticeCount: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d notice",
    //         one: "%d notice",
    //         other: "%d notices",
    //     }, num, d1);
    // },
    // messageCount: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d message",
    //         one: "%d message",
    //         other: "%d message",
    //     }, num, d1);
    // },
    // postCount: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d post",
    //         one: "%d post",
    //         other: "%d posts",
    //     }, num, d1);
    // },
    // eventRequestCount: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d event request",
    //         one: "%d event request",
    //         other: "%d event requests",
    //     }, num, d1);
    // },
    // minute: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d minute",
    //         one: "%d minute",
    //         other: "%d minutes",
    //     }, num, d1);
    // },
    // hour: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d hour",
    //         one: "%d hour",
    //         other: "%d hours",
    //     }, num, d1);
    // },
    // day: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d day",
    //         one: "%d day",
    //         other: "%d days",
    //     }, num, d1);
    // },
    // month: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d month",
    //         one: "%d month",
    //         other: "%d months",
    //     }, num, d1);
    // },
    // year: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d year",
    //         one: "%d year",
    //         other: "%d years",
    //     }, num, d1);
    // },
    // second_ago: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d second ago",
    //         one: "%d second ago",
    //         other: "%d seconds ago",
    //     }, num, d1);
    // },
    // minute_ago: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d minute ago",
    //         one: "%d minute ago",
    //         other: "%d minutes ago",
    //     }, num, d1);
    // },
    // hour_ago: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d hour ago",
    //         one: "%d hour ago",
    //         other: "%d hours ago",
    //     }, num, d1);
    // },
    // day_ago: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d day ago",
    //         one: "%d day ago",
    //         other: "%d days ago",
    //     }, num, d1);
    // },
    // week_ago: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d week ago",
    //         one: "%d week ago",
    //         other: "%d weeks ago",
    //     }, num, d1);
    // },
    // month_ago: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d month ago",
    //         one: "%d month ago",
    //         other: "%d months ago",
    //     }, num, d1);
    // },
    // year_ago: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d year ago",
    //         one: "%d year ago",
    //         other: "%d years ago",
    //     }, num, d1);
    // },
    // show_reply: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "Show %d reply",
    //         one: "Show %d reply",
    //         other: "Show %d replies",
    //     }, num, d1);
    // },
    // comment: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d Comment",
    //         one: "%d Comment",
    //         other: "%d Comments",
    //     }, num, d1);
    // },
    // reply: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d Reply",
    //         one: "%d Reply",
    //         other: "%d Replies",
    //     }, num, d1);
    // },
    // event_quick_creation: (num, o1, o2, o3) => {
    //     return pluralSwitch({
    //         other: "WITH %o MEMBERS , AFTER %o , DURATION OF %o",
    //     }, num, o1, o2, o3);
    // },
    // wiz_name_minimum_length_msg: (num, d1) => {
    //     return pluralSwitch({
    //         one: "Minimum length of %d character",
    //         other: "Minimum length of %d characters",
    //     }, num, d1);
    // },
    // wiz_name_maximum_length_msg: (num, d1) => {
    //     return pluralSwitch({
    //         one: "Maximum length of %d character",
    //         other: "Maximum length of %d characters",
    //     }, num, d1);
    // },
    // maximum_length_msg: (num, d1) => {
    //     return pluralSwitch({
    //         one: "Maximum length of %d character",
    //         other: "Maximum length of %d characters",
    //     }, num, d1);
    // },
    // password_maximum_length_msg: (num, d1) => {
    //     return pluralSwitch({
    //         one: "Maximum length of %d character",
    //         other: "Maximum length of %d characters",
    //     }, num, d1);
    // },
    // password_minimum_length_msg: (num, d1) => {
    //     return pluralSwitch({
    //         one: "Minimum length of %d character",
    //         other: "Minimum length of %d characters",
    //     }, num, d1);
    // },
    // members: (num, o1) => {
    //     return pluralSwitch({
    //         zero: "Member",
    //         one: "Member",
    //         other: "Members",
    //     }, num, o1);
    // },
    // views: (num, d1) => {
    //     return pluralSwitch({
    //         zero: "%d view",
    //         one: "%d view",
    //         other: "%d views",
    //     }, num, d1);
    // },
};

export const KR_PLURALS = plurals;
