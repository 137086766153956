import React, {useState} from "react";
import PropTypes from "prop-types";
import SidebarContentContainer from "../../containers/SidebarContentContainer";
import {strings} from "../../values/strings";

function PageProperty(props) {

    const {
    } = props

    return (
        <SidebarContentContainer
            defaultIndex={0}
            tabs={[
                {
                    title: strings.theme // global color,
                    // layout: (
                    //     focusedId ?
                    //         <ComponentDesignPropertiesSidebar
                    //             id={focusedId}
                    //             source={focusedSource}
                    //             data={focusedProps}
                    //             // onChange={}
                    //         />
                    //         :
                    //         <PageDesignPropertiesSidebar
                    //             data={layout}
                    //             onChange={handlePageLayoutChange}
                    //         />
                    // ),
                },
            ]}
        />
    )
}

PageProperty.propTypes = {
}

PageProperty.defaultProps = {
}

export default PageProperty