const dimens = {
    tab: {
        width: 180,
        margin: 32,
    },
    sidebar: {
        width: 350,
        margin: 24,
    },
    toolbar: {
        height: 80,
    },
    bottombar: {
        height: 64,
        margin: 32,
    },



    flow: {
        gap: 56,
        minGap: 32,
        margin: 50
    },
    row: {
        gap: 12,
    },
    block: {
        minWidth: 80,
        height: 70,
        paddingX: 24,
    },
    caseBlock: {
        minWidth: 144,
        height: 64,
        gap: 95,
    },
    errorBlock: {

    },
    stepper: {
        padding: 16,
    },
    rail: {
        width: 4
    },
    indicator: {
        width: 12,
        maxWidth: 18,
    }
}

export default dimens;