import React from "react";
import PropTypes from "prop-types";
import {Stack, Typography} from "@mui/material";
import {strings} from "../../values/strings";
import ScenarioSelect from "./ScenarioSelect";

function Peek(
    {
        height,
        onClick,
        countVariables,
        selectProps,
        sx,
        ...rest
    }
) {
    return (
        <Stack
            direction={'row'}
            onClick={onClick}
            {...rest}
            sx={{
                width: '100%',
                height: height,
                alignItems: 'center',
                justifyContent: 'center',
                boxSizing: 'border-box',
                cursor: 'pointer',
                ...sx,
            }}
        >
            <Typography
                variant={'subtitle2'}
                color={'primary.contrastText'}
                flexGrow={1}
                p={'10px'}
                noWrap
                textTransform={'capitalize'}
            >
                {strings.show_all_n_variables.format(countVariables)}
            </Typography>

            <ScenarioSelect {...selectProps} />
        </Stack>
    )
}

Peek.propTypes = {
    height: PropTypes.any,
    onClick: PropTypes.func,
    countVariables: PropTypes.number,
    selectProps: PropTypes.object,
}

Peek.defaultProps = {
}

export default Peek