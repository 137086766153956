import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createRoot} from "react-dom/client";
import {GlobalStateProvider, MuiThemeProvider} from "@wizlit/react-component";
import {darkTheme, lightTheme} from "./values/theme";
import {ThemeProvider, useMediaQuery} from "@mui/material";
// import ExportedProvider from "./res/providers/ExportedProvider";
import {BrowserRouter} from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import AlertDialogProvider from "./components/AlertDialogProvider";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

String.prototype.format = function() {
    let string = this;
    for (let index in arguments) string = string.replaceAll("{" + index + "}", arguments[index])
    return string
}

String.prototype.autoConvertType = function() {
    let num = Number(this);
    if (typeof this === 'boolean' || this === 'true' || this === 'false') {
        return Boolean(this)
    } else if (isNaN(num)) {
        return this;
    } else {
        return num;
    }
}

const app = (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GlobalStateProvider>
            {/*<ExportedProvider>*/}
            {/*<Account>*/}
            {/*<HelmetProvider>*/}
                <MuiThemeProvider
                    defaultMode={'dark'}
                    lightTheme={lightTheme}
                    darkTheme={darkTheme}
                    themeProviderComponent={ThemeProvider}
                    useMediaQuery={useMediaQuery}
                >
                    {/*<MultiLingualProvider>*/}
                    {/*    <PreferenceProvider>*/}
                    {/*        <BrowserRouter>*/}
                    {/*            <BackgroundProcessProvider>*/}
                    {/*                <DialogsProvider>*/}
                    {/*                    <LocationStackProvider>*/}

                    <AlertDialogProvider>
                        <SnackbarProvider maxSnack={3}>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </SnackbarProvider>
                    </AlertDialogProvider>

                                        {/*</LocationStackProvider>*/}
                                    {/*</DialogsProvider>*/}
                                {/*</BackgroundProcessProvider>*/}
                            {/*</BrowserRouter>*/}
                        {/*</PreferenceProvider>*/}
                    {/*</MultiLingualProvider>*/}
                </MuiThemeProvider>
            {/*</HelmetProvider>*/}
            {/*</Account>*/}
            {/*</ExportedProvider>*/}
        </GlobalStateProvider>
    </LocalizationProvider>
)

const container = document.getElementById('root');
const root = createRoot(container);
root.render(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
