import React from "react";
import {IconButton, useTheme} from "@mui/material";
import {CloseRounded} from "@mui/icons-material";
import PropTypes from "prop-types";

function CloseFloatingButton(props) {
    const theme = useTheme();

    const {
        buttonProps,
        iconColor = "primary",
        iconProps,
        onClick,
        disabled,
        fixed,
        ...rootProps
    } = props;

    return (
        <div
            {...rootProps}
            style={{
                transition: "0.2s",
                ...fixed ? {
                    position: "fixed",
                    zIndex: 600,
                    top: 32,
                    right: 32,
                } : {},
                ...rootProps?.style
            }}
        >
            <IconButton
                onClick={onClick}
                disabled={disabled}
                disableRipple
                {...buttonProps}
                sx={{
                    opacity: disabled ? .5 : 1,
                    width: 28,
                    height: 28,
                    backgroundColor: theme.palette.primary.contrastText,
                    color: theme.palette.primary.main,
                    boxShadow: `0 3px 1rem rgba(0,0,0,.5)`,
                    transition: "0.2s",
                    "&.Mui-focusVisible, &.MuiIconButton-root": {
                        backgroundColor: theme.palette.primary.contrastText,
                        // "&:hover": { backgroundColor: `${theme.palette.text.primary}99`}
                    },
                    ...buttonProps?.sx,
                }}
            >
                <CloseRounded
                    color={iconColor}
                    fontSize={"small"}
                    {...iconProps}
                />
            </IconButton>
        </div>
    );
}

CloseFloatingButton.propTypes = {
    buttonProps: PropTypes.shape(IconButton.prototype),
    iconColor: PropTypes.string,
    iconProps: PropTypes.shape(CloseRounded.propTypes),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    fixed: PropTypes.bool,
}

CloseFloatingButton.defaultProps = {
    fixed: true,
}

export default CloseFloatingButton;
