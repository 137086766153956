import React, {useContext} from "react";
import PropTypes from "prop-types";
import {strings} from "../../values/strings";
import {useTheme, Typography} from "@mui/material";
import SquareIconButton from "../../components/SquareIconButton";
import {AlertContext} from "../../components/AlertDialogProvider";

function WidgetToolbar(props) {

    const {
        id,
        cancelClick = () => {},
        archiveClick = () => {},
        deleteClick = () => {},
    } = props

    const theme = useTheme()
    const alertContext = useContext(AlertContext)

    return <>
        <SquareIconButton onClick={cancelClick}>keyboard_backspace</SquareIconButton>

        <Typography variant={'caption'} noWrap color={'primary.contrastText'} flexGrow={1} sx={{opacity: .3}}>
            {id}
        </Typography>

        <SquareIconButton
            hint={strings.deploy}
            // onClick={() => handleConfirmation(
            //     strings.delete_widget_title,
            //     strings.delete_widget_description,
            //     strings.delete,
            //     'error',
            //     deleteClick
            // )}
        >
            publish
        </SquareIconButton>

        <SquareIconButton
            color='info'
            hint={strings.archive}
            // size={22}
            // onClick={() => handleConfirmation(
            //     strings.archive_widget_title,
            //     strings.archive_widget_description,
            //     strings.archive,
            //     'info',
            //     archiveClick
            // )}
            onClick={archiveClick}
        >
            archive
        </SquareIconButton>

        <SquareIconButton
            color='error'
            hint={strings.delete}
            onClick={() => alertContext.alert({
                title: strings.delete_widget_title,
                content: strings.delete_widget_description,
                confirmText: strings.delete,
                confirmColor: 'error',
                action: deleteClick
            })}
        >
            delete
        </SquareIconButton>
    </>
}

WidgetToolbar.propTypes = {
    id: PropTypes.string,
    cancelClick: PropTypes.func,
    archiveClick: PropTypes.func,
    deleteClick: PropTypes.func,
}

WidgetToolbar.defaultProps = {
}

export default WidgetToolbar