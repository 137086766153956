import React from "react";
import {useTheme} from "@mui/material";

function SwipeThumb() {
    const theme = useTheme();
    return (
        <span
            style={{
                height: 4,
                width: 50,
                backgroundColor: `${theme.palette.primary.contrastText}3A`,
                position: 'absolute',
                top: 8,
                left: '50%',
                marginTop: 0,
                WebkitTransform: 'translateX(-50%)',
                transform: 'translateX(-50%)',
            }}
        />
    )
}

export default SwipeThumb