import React from "react";
import PropTypes from "prop-types";
import {Button, Tooltip, useTheme} from "@mui/material";
import {MaterialIcon} from "../MaterialIcon";

const SquareIconButton = (
    {
        children,
        sx,
        color,
        hint,
        fill,
        size,
        buttonSize,
        label,
        other,
        ...rest
    }
) => {
    const theme = useTheme()
    return (
        <Tooltip title={hint} placement={'top'}>
            <Button
                disableElevation
                color={color}
                {...rest}
                sx={{
                    color: !color && theme.palette.primary.contrastText,
                    borderColor: !color && theme.palette.primary.contrastText,
                    minWidth: !!label ? 'auto' : buttonSize,
                    maxWidth: !!label ? 'auto' : buttonSize,
                    height: buttonSize,
                    mx: '2px',
                    px: !!label && 1.2,
                    textTransform: 'capitalize',
                    lineHeight: 1,
                    ...sx
                }}
            >
                {children &&
                    <MaterialIcon size={size} fill={fill} style={!!label && {marginRight: 4}}>
                        {children}
                    </MaterialIcon>
                }

                {label}
                {other}
            </Button>
        </Tooltip>
    )
}

SquareIconButton.propTypes = {
    children: PropTypes.any,
    color: PropTypes.string,
    hint: PropTypes.string,
    size: PropTypes.number,
    buttonSize: PropTypes.number,
    fill: PropTypes.bool,
    label: PropTypes.any,
    other: PropTypes.any,
}

SquareIconButton.defaultProps = {
    size: 24,
    buttonSize: 24,
}

export default SquareIconButton