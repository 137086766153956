import React, {useContext} from "react";
import PropTypes from "prop-types";
import {Button, MenuItem, Select, useTheme} from "@mui/material";
import {KeyboardArrowUp} from "@mui/icons-material";
import {strings} from "../../values/strings";
import {MaterialIcon} from "../../components/MaterialIcon";
import {AlertContext} from "../../components/AlertDialogProvider";

function ScenarioSelect (
    {
        value,
        options,
        onChange = (newValue) => {},
        onAdd = () => {},
        sx,
        ...rest
    }
) {
    const theme = useTheme()
    const alertContext = useContext(AlertContext)

    const handleChange = (event) => {
        if (event.target.value !== '__ignore') onChange(event)
    }

    return (
        <Select
            value={value}
            onClick={e => e.stopPropagation()}
            onChange={handleChange}
            autoWidth
            IconComponent={KeyboardArrowUp}
            sx={{
                ...theme.typography.subtitle2,
                background: theme.palette.primary.light,
                height: 'calc(100% - 8px)',
                borderRadius: '8px',
                m: !rest.m &&'4px',
                boxShadow: 'none',
                cursor: 'pointer',
                maxWidth: 150,
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '.MuiSelect-icon': { fontSize: 16 },
                ...sx,
            }}
            {...rest}
        >
            <MenuItem value={'default'} sx={theme.typography.subtitle2}>
                {strings.default}
            </MenuItem>

            {options?.map((_option, _index) =>
                <MenuItem
                    key={_index}
                    value={_option.id}
                    sx={theme.typography.subtitle2}
                    disableRipple
                >
                    {_option.name}
                </MenuItem>
            )}

            <Button
                value={'__ignore'}
                fullWidth
                startIcon={<MaterialIcon size={22}>add</MaterialIcon>}
                onClick={() => alertContext.alert({
                    title: strings.create_new_scenario,
                    type: 'textField',
                    contentProps: {
                        label: strings.scenario_name
                    },
                    confirmText: strings.create,
                    confirmColor: 'success',
                    action: onAdd,
                })}
                color={'success'}
                disableRipple
                sx={{
                    ...theme.typography.subtitle2,
                    '& > .MuiButton-startIcon': {
                        marginRight: .3,
                    }
                }}
            >
                {strings.create_new_scenario}
            </Button>
        </Select>
    )
}

ScenarioSelect.propTypes = {
    value: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        })
    ),
    onChange: PropTypes.func,
    onAdd: PropTypes.func,
}

ScenarioSelect.defaultProps = {
    value: 'default'
}

export default ScenarioSelect