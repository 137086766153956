import PropTypes from "prop-types";
import {Stack, useTheme} from "@mui/material";
import dimens from "../../values/dimens";

function SidebarContainer(props) {
    const {children, sx, fixed, overlay, ...rest} = props
    const theme = useTheme()

    return (
        <Stack
            boxSizing={"border-box"}
            maxWidth={dimens.sidebar.width}
            minWidth={dimens.sidebar.width}

            {...rest}

            sx={{
                position: 'relative',
                transition: ".2s",
                boxSizing: 'border-box',
                boxShadow: "0px 0px 24px #00000020",
                borderRadius: 4,
                overflow: 'hidden',
                background: theme.palette.primary.light,
                userSelect: "none",
                msUserSelect: "none",
                MozUserSelect: "none",
                WebkitUserSelect: "none",
                ...fixed ? {
                    position: "fixed",
                    top: dimens.sidebar.margin,
                    bottom: dimens.sidebar.margin,
                    right: dimens.sidebar.margin,
                    maxHeight: `calc(100% - ${dimens.sidebar.margin * 2}px)`,
                } : {},
                ...sx
            }}
        >
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    pointerEvents: 'none',
                }}
            >
                {children}

                <Stack
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        justifyContent: 'end',
                        zIndex: 2,
                    }}
                >
                    {overlay}
                </Stack>
            </div>
        </Stack>
    )
}

SidebarContainer.propTypes = {
    children: PropTypes.any,
    fixed: PropTypes.bool,
    overlay: PropTypes.any,
}

SidebarContainer.defaultProps = {
    fixed: true,
}

export default SidebarContainer;